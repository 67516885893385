import React, { useRef, useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { NavLink, Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 

import ContactInfo from "./reusableComponents/ContactInfo";
import SocialMedia from "./reusableComponents/SocialMedia";

// Slideshow Image
import slide1 from "../media/slider-img1.jpg";
import slide2 from "../media/slider-img2.jpg";
import slide3 from "../media/slider-img3.jpg";
import slide4 from "../media/slider-img4.jpg";
import slide5 from "../media/slider-img5.jpg";
// Other Images
import promiseBadge from "../media/our-promise-guaranteed-2.png";
import saleImg from "../media/current-specials.png";
import sampleDesign from "../media/sample-layouts.png";
import weCare from "../media/we-care-more-about-your-event.png";
import thumbsUp from "../media/photo-booth-customer-satisfaction.png";
import boothIcon from "../media/photo-booth.png";
import cameraIcon from "../media/photo-booth-equipment.png";
import technicianIcon from "../media/photo-booth-technician.png";
import propIcon from "../media/photo-booth-props.png";
import downloadIcon from "../media/download-photo-booth-pictures.png";
import weddingWire from "../media/badge-rated-100.png";
import weddingAward from "../media/wedding-awards.png";
import customerBadge from "../media/best-customer-experience-award.png";

import love from "../media/love.png";

export default function Home() {
  const isMobile = useMediaQuery({ query: `(max-width: 800px)` });
  const slideImages = [slide1, slide2, slide3, slide4, slide5];
  const checkMobile = () => {
    if (isMobile) {
      return false 
    } else if(!isMobile) {
      return true
    }
  }
  const settings = {
    dots: checkMobile(),
    fade: true,
    arrows: false,
    infinite: true,
    autoplay:true,
    autoplaySpeed:3500,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => <ul>{dots}</ul>,
    
  };
  const homeSectionArray = [
    {
      img: promiseBadge,
      alt: "Our promise to you badge",
      title: "Our",
      highlight: "Promise",
      text: "It is our commitment and promise to you that we will only deliver our best customer service and care.",
      buttonText: "Our Guarantee",
      link: "/ourPromise",
    },
    {
      img: saleImg,
      alt: "Current Specials",
      title: "Current",
      highlight: "Specials",
      text: "We have great savings on our photo booth rental packages. Everything you need for one great price.",
      buttonText: "View Our Specials",
      link: "/prices",
    },
    {
      img: sampleDesign,
      alt: "Sample Designs",
      title: "Sample",
      highlight: "Designs",
      text: "To view some of our latest samples and designs, click on the link below for more information.",
      buttonText: "Design Samples",
      link: "/sampleLayouts",
    },
  ];
  const homeInfoArray = [
    {
      img: thumbsUp,
      title: "Customer Satisfaction",
      text: "We have tons of 5 Star ratings across the web on WeddingWire, Facebook, Google, Yelp and many more. We provide a great product with outstanding service – What’s not to love?",
    },
    {
      img: boothIcon,
      title: "Open Concept Photo Booth",
      text: "Our photo booth design is open concept that allows us to accommodate larger groups of people.  The open concept allows all of the guests to watch and participate in the fun.  We don’t like to hide the fun behind a curtain.",
    },
    {
      img: cameraIcon,
      title: "Quality Photos + Equipment",
      text: "Our photo booths are equipped with the latest DSLR camera and printing technology.  Each photo is captured in high resolution and printed using only professional grade printers.",
    },
    {
      img: technicianIcon,
      title: "Onsite Technicians",
      text: "Our technicians are educated, experienced and charming to ensure our service is completed to perfection.  Our attendants know photo booths.",
    },
    {
      img: propIcon,
      title: "Amazing Props",
      text: "How much fun is a photo booth with great props?  We challenge you to outfit yourself with as many of our props as you can.",
    },
    {
      img: downloadIcon,
      title: "Download Your Pictures",
      text: "After your event, we will provide you with a link to view, download and share your digital pictures.",
    },
  ];
  return (
    <div>
      <div className="wrapper">

      <Slider {...settings}>
        {slideImages.map((slide, index) => {
          return (
            <div key={index}>
          <div>
            <img src={slide} alt={`Slide Image ${index}`} />
          </div>
        </div>          
           )
          })}   
      </Slider>

      </div>
        
      <section className="homeSectionContainer wrapper">
        {homeSectionArray.map((section, i) => {
          return (
            <div key={i} className="homeSectionIndivContainer">
              <img
                src={section.img}
                alt={section.alt}
                aria-hidden="true"
                className="homeSectionImg"
              />
              <h2 className="orangeBlackTitle">
                {section.title}{" "}
                <span className="highlightOrange">{section.highlight}</span>
              </h2>
              <p>{section.text}</p>
              <button className="purpleButton">
                <NavLink to={section.link}>{section.buttonText}</NavLink>
              </button>
            </div>
          );
        })}
      </section>
      <div className="weCareImg wrapper">
        <img src={weCare} alt="We care more about your event" />
      </div>
      <section className=" homeInfoSection">
        <ul className="homeInfoContainer wrapper">
          {homeInfoArray.map((info, i) => {
            return (
              <li key={i} className="homeInfoIndivContainer">
                <img
                  src={info.img}
                  alt={info.title}
                  aria-hidden="true"
                  className="homeInfoIcon"
                />
                <h3>{info.title}</h3>
                <p>{info.text}</p>
              </li>
            );
          })}
        </ul>
      </section>
      <section className="homeRentalList">
        <div className="wrapper homeRentalContainer">
          <h2>TMP Photo Booth Rentals Are Perfect For:</h2>
          <p>
            Weddings | Corporate Events | Graduations | School Dances | Birthday
            Parties | Holiday Parties | Bar Mitzvahs | Bat Mitzvahs | Charity
            Fundraiser's | Promotional Events
          </p>
        </div>
      </section>
      <section className="homeAwardSection wrapper">
        <div className="welcomeContainer ">
          <h3>Welcome</h3>
          <p>
            A photo booth has always inspired excitement, spontaneity, and lots
            of fun. With the latest digital technology and ultra-compact design,
            our versatile photo booths are guaranteed to be a great addition to
            your next special event.
          </p>
          <p>
            Take My Photo is the most value added photo booth rental company in
            Ontario for good reason! We provide the best customer service and
            the most value well beyond the cost. Our head office is just north
            of Toronto, but don’t worry, we will travel throughout Ontario to
            bring a photo booth to your special event. Give us a call and we
            will answer all your questions.
          </p>
          <p>
            Our fun and skilled event staff will be on site to ensure your
            guests enjoy a smooth and unforgettable experience! Your guests will
            have a good laugh, create memorable moments, and at the end of the
            event they will go home with a charming keepsake.
          </p>
          <h4>Best. Day. Ever</h4>
        </div>
        <div className="homeAwardsIndiv">
          <h3>Glowing Accolades</h3>
          <ul className="glowingSection">
            <li>
              <Link
                to="https://www.weddingwire.ca/photobooth/take-my-photo--e7858"
                target="_blank"
              >
                <img src={weddingWire} alt="WeddingWire rated 201 Reviews" />
              </Link>
            </li>
            <li>
              <Link
                to="https://www.weddingwire.ca/photobooth/take-my-photo--e7858"
                target="_blank"
              >
                <img
                  src={weddingAward}
                  alt="WeddingWire Photo Booth Award for 2021"
                />
              </Link>
            </li>
            <li>
              <Link
                to="https://www.weddingwire.ca/photobooth/take-my-photo--e7858"
                target="_blank"
              >
                <img
                  src={customerBadge}
                  alt="Best Customer Experience Award for 2021"
                />
              </Link>
            </li>
          </ul>
        </div>
        <div className="homeAwardsIndiv">
          <h3>Get In Touch</h3>
          <ContactInfo />
          <h3>Stay Connected</h3>
          <SocialMedia />
          <div className="loveContainer">
            <img src={love} alt="Love" className="loveImg" />
          </div>
        </div>
      </section>
    </div>
  );
}
