import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import logo from "../../media/Take-My-Photo-Logo.png";
import { FaHome } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";

export default function Nav() {
  const location = useLocation();
  const isMobile = useMediaQuery({ query: `(max-width: 950px)` });
  const [isDisplay, setIsDisplay] = useState(false);

  const displayMobileMenu = () => {
    setIsDisplay((current) => !current);
  };

  const handleHightlight = (name) => {
    if (location.pathname === name) {
      return "highlight";
    }
  };
  // Close mobile menu on pathname change
  useEffect(() => {
    setIsDisplay(false);
  }, [location]);

  return (
    <div className="wrapper deskMenuContainer">
      <div className="logoContainer wrapper">
        <NavLink to="">
          <img className="logo" src={logo} alt="Take My Photo logo" />
        </NavLink>
      </div>
      <nav>
        {isMobile && (
          <ul className="mobileMenuContainer">
            <li className="mobileMenuHam" onClick={displayMobileMenu}>
              Menu
              <div>{!isDisplay && <FiMenu className="hamburger" />}</div>
            </li>
            {isDisplay && (
              <ul className="mobileMenu">
                <li className={handleHightlight("/")}>
                  <NavLink to="">Home</NavLink>
                </li>
                <li className={handleHightlight("/about")}>
                  <NavLink to="/about">About</NavLink>
                </li>
                <li className={handleHightlight("/prices")}>
                  <NavLink to="/prices">Prices</NavLink>
                </li>
                <li className={handleHightlight("/reviews")}>
                  <NavLink to="/reviews">Reviews</NavLink>
                </li>
                <li className={handleHightlight("/faq")}>
                  <NavLink to="/faq">FAQ's</NavLink>
                </li>
                <li className={handleHightlight("/contactUs")}>
                  <NavLink to="/contactUs">Contact Us</NavLink>
                </li>
              </ul>
            )}
          </ul>
        )}
        {!isMobile && (
          <ul className="deskMenu">
            <li className={handleHightlight("/")}>
              <NavLink to="">
                <FaHome className="faHome" aria-hidden="true" />
                Home
              </NavLink>
            </li>
            <li className={handleHightlight("/about")}>
              <NavLink to="/about">About</NavLink>
            </li>
            <li className={handleHightlight("/prices")}>
              <NavLink to="/prices">Prices</NavLink>
            </li>
            <li className={handleHightlight("/reviews")}>
              <NavLink to="/reviews">Reviews</NavLink>
            </li>
            <li className={handleHightlight("/faq")}>
              <NavLink to="/faq">FAQ's</NavLink>
            </li>
            <li className={handleHightlight("/contactUs")}>
              <NavLink to="/contactUs">Contact Us</NavLink>
            </li>
          </ul>
        )}
      </nav>
    </div>
  );
}
