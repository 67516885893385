import React from "react";
import { NavLink } from "react-router-dom";
import ContactInfo from "./reusableComponents/ContactInfo";

// Charities Img
import sickKids from "../media/charities/sick-kids-foundation.png";
import arthritis from "../media/charities/arthritis-research-foundation.jpg";
import mandarin from "../media/charities/ms-walk.png";
import julyna from "../media/charities/julyna.png";
import memoryBall from "../media/charities/memory-ball.jpg";
import micheleTemple from "../media/charities/michele-temple.jpg";
import sunshine from "../media/charities/project-sunshine.jpg";
import paws from "../media/charities/paws-for-the-cause.jpg";
import cnib from "../media/charities/cnib.png";

import certifiedFunImg from "../media/photo-booth-rental-certified-fun.png";
import { FiCheck } from "react-icons/fi";
import quote from "../media/Quote.png";

export default function About() {
  const charitiesArray = [
    { img: sickKids, alt: "Sick Kids Foundation logo" },
    { img: arthritis, alt: "Arthritis Research Foundation logo" },
    { img: mandarin, alt: "Mandarin MS. Walk Every Step Matters logo" },
    { img: julyna, alt: "Julyna logo" },
    { img: memoryBall, alt: "Memory Ball logo" },
    {
      img: micheleTemple,
      alt: "The Michele Temple Melanoma Fundraising Gala logo",
    },
    {
      img: sunshine,
      alt: "Project Sunshine bringing sunshine to a cloudy day logo",
    },
    { img: paws, alt: "PAWS For The Cause logo" },
    { img: cnib, alt: "CNIB seeing beyond vision loss logo" },
  ];
  const servicesArray = [
    "Weddings",
    "Corporate Events",
    "Graduations",
    "School Events",
    "Birthday Parties",
    "Holiday Parties",
    "Bar/Bat Mitzvahs",
    "Charity Fundraiser",
    "Company Parties",
    "Trade Shows",
    "Festivals",
    "Promotional Event",
  ];
  const aboutTestimonialsArray = [
    {
      text: "Take My Photo was the perfect addition to our event. They were friendly, energetic, and our guests loved their pictures. They were amazing to work with.",
      name: "Jessica M.,",
      place: "George Brown",
    },
    {
      text: "Brian was always quick to respond to my emails and answer all of my questions. There were very professional and great with all of the kids. I will definitely recommend them to others.",
      name: "Angela L.,",
      place: "Global Precase",
    },
    {
      text: "Professional and friendly service. I loved having Take My Photo at my party and I will definitely be working with them again in the future.",
      name: "Luiz A.,",
      place: "EF Toronto",
    },
  ];
  return (
    <div>
      <div className="aboutContainer wrapper">
        <div className="aboutMainContainer">
          <section className="aboutSection">
            <h1 className="orangeBlackTitle">
              About <span className="highlightOrange">Take My Photo</span> -
              Behind the Camera
            </h1>
            <p className="highlightOrange aboutP">
              Take My Photo is a premiere photo booth rental company that serves
              all of Ontario.
            </p>
            <p>
              We offer an ultra-compact, versatile, and very portable photo
              booth that is built with the latest DSLR camera and printer
              technology. I guess you could say that we are the Go-Anywhere
              photo booth rental company.
            </p>
            <p>
              We provide an extremely fun and interactive photo booth experience
              for your wedding, graduation, party, or any other special event.
              Our photo booth’s have been enjoyed at bridal showers, corporate
              events, birthday parties, and family reunions…the possibilities
              are endless!
            </p>
            <p>
              We are committed to providing you and your guests with the best
              customer service and care with an experience that will leave
              everyone talking long after the event has ended. We love what we
              do and it shows from our energy and excitement. It would be our
              absolute pleasure to be part of your special day or next event. We
              will always work hard to provide a unique and lasting memory for
              you and your guests.
            </p>
          </section>
          <section>
            <h2 className="orangeBlackTitle">
              Supporting <span className="highlightOrange">Our Community</span>
            </h2>
            <p>
              Take My Photo strongly believes in supporting and giving back to
              our community. We have proudly provided our photo booth services
              to several charities and non-profit organizations including:
            </p>
            <ul className="charitiesContainer">
              {charitiesArray.map((charity, i) => {
                return (
                  <li key={i} className="charitiesImg">
                    <img src={charity.img} alt={charity.alt} />
                  </li>
                );
              })}
            </ul>
            <p className="charitiesInquire">
              If you are inquiring about our photo booth services for your
              charity or non-profit organization, please{" "}
              <NavLink to="/contactUs">contact us</NavLink> for more
              information. We are always more than happy to help.
            </p>
          </section>
        </div>
        <div className="aboutAsideContainer">
          <aside>
            <h2 className="orangeBlackTitle">
              Contact <span className="highlightOrange">Us Today</span>
            </h2>
            <ContactInfo />
          </aside>
          <div className="certifiedFunImg">
            <img src={certifiedFunImg} alt="100% certified fun!" />
          </div>
          <aside className="servicesContainer">
            <h2 className="orangeBlackTitle">
              Our <span className="highlightOrange">Services</span>
            </h2>
            <p>We provide photo booth services to all types of events:</p>
            <ul>
              {servicesArray.map((service, i) => {
                return (
                  <li key={i}>
                    <FiCheck aria-hidden="true" className="fiCheck" />
                    <p>{service}</p>
                  </li>
                );
              })}
            </ul>
          </aside>
        </div>
      </div>
      <section className="homeInfoSection aboutTestimonials">
        <h3>Testimonials</h3>
        <div className="wrapper testimonials">
          {aboutTestimonialsArray.map((testimonial, i) => {
            return (
              <div key={i} className="testimonialContainer">
                <img
                  src={quote}
                  alt="Quote"
                  aria-hidden="true"
                  className="quote"
                />
                <div className="testimonialText">
                  <p>{testimonial.text}</p>
                  <p className="name">
                    <span>{testimonial.name}</span> {testimonial.place}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <NavLink to="/reviews">
          <button className="purpleButton">
            Read Our Awesome Testimonials
          </button>
        </NavLink>
      </section>
    </div>
  );
}
