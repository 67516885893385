import React from "react";

export default function Faq() {
  return (
    <div className="privacyWindow wrapper">
      <div className="faqTitle">
        <h2 className="orangeBlackTitle">
          Frequently <span className="highlightOrange">Asked Questions</span>
        </h2>
      </div>

      <div className="faqContent">
        <div className="question">
          <p>How early do I need to book a photo booth before my event date?</p>
        </div>

        <div className="faqAnswer">
          <p>
            We recommend you reserve a photo booth as early as possible since
            our availability fills up fast. A small non-refundable deposit of
            $100 is required to hold and secure the photo booth for your event
            date and time. We accept cheque, cash, credit cards, PayPal, and
            email money transfers.
          </p>
        </div>

        <hr className="reviewHr" />

        <div className="question">
          <p>Why Take My Photo?</p>
        </div>

        <div className="faqAnswer">
          <p>
            What sets us apart from others? We specialize in photo booth rentals
            and most importantly, as the owners of the company, we will be
            on-site to operate the photo booth for your wedding or event. We
            like to interact and engage with the guests by offering suggestions
            for different poses and providing the best and most memorable photo
            booth experience for you and your guests.
          </p>
          <p>
            Take My Photo is Ontario's most affordable Go-Anywhere photo booth
            rental company. We provide the best customer service and care and
            the most value well beyond the cost. We are based just north of
            Toronto, but don't worry, we will travel throughout Ontario to bring
            a portable photo booth to your special event. Additional travel
            charges may apply.
          </p>
          <p>
            We understand there may be less expensive photo booth solutions
            available, however, our company definitely provides the most value
            well beyond the cost and we absolutely guarantee the highest level
            of customer service. We encourage you to read our reviews from past
            clients. We are proud of our reviews because they truly represent
            the value and hard work that we provide to each of our clients.
          </p>
        </div>

        <hr className="reviewHr" />

        <div className="question">
          <p>Can we have a special message on our photo booth prints?</p>
        </div>

        <div className="faqAnswer">
          <p>
            Absolutely! Our in house graphic design team will create a custom
            template with your name, date, company logo, images, or anything
            else you would like. It will truly be one of a kind.
          </p>
        </div>

        <hr className="reviewHr" />

        <div className="question">
          <p>How portable is your photo booth?</p>
        </div>

        <div className="faqAnswer">
          <p>
            Our photo booth is ultra-compact and versatile. It can be taken up
            any stairs, it fits in any elevator, it can be indoors or outdoors,
            you can even take photos on the beach, however, it does require an
            electrical outlet for power.
          </p>
        </div>

        <hr className="reviewHr" />

        <div className="question">
          <p>What kind of printer do you use?</p>
        </div>

        <div className="faqAnswer">
          <p>
            We use the latest digital printing technology - professional
            dye-sublimation printers. These printers produce photo lab quality
            digital prints in as little as 8 seconds. Every printed photo will
            not fade, smudge, or smear.
          </p>
        </div>

        <hr className="reviewHr" />

        <div className="question">
          <p>
            I want to see a sample of the print quality before I decide to book
            a photo booth. Is that possible?
          </p>
        </div>

        <div className="faqAnswer">
          <p>
            Yes. Please <a href="/contactUs">contact us</a> via email or phone
            and we will be more than happy to send you some sample photo prints
            from past events.
          </p>
        </div>

        <hr className="reviewHr" />

        <div className="question">
          <p>Do my guests have to pay to use the booth?</p>
        </div>

        <div className="faqAnswer">
          <p>
            No. The photo booth rental is all-inclusive and available to
            everyone at the event. You and your guests can use the photo booth
            as often as you would like for the duration of the time booked.
          </p>
        </div>

        <hr className="reviewHr" />

        <div className="question">
          <p>What is included in the photo booth rental?</p>
        </div>

        <div className="faqAnswer">
          <p>
            Your photo booth rental is All-Inclusive. Everything you need to
            make your event fun and entertaining is provided for a great price.
            With every rental, you receive...
          </p>
          <ul className="answerList">
            <li>
              <p>Unlimited use of the photo booth during rental period.</p>
            </li>
            <li>
              <p>
                Instant digital photos printed on-site - colour or black &
                white.
              </p>
            </li>
            <li>
              <p>
                Custom and personalized photo strip design - can include any
                text, colours, company logo, images, in any design style.
              </p>
            </li>
            <li>
              <p>
                Choice of silver sequin, gold sequin, light grey or cream
                backdrop.
              </p>
            </li>
            <li>
              <p>
                Delivery, set up, and take down of photo booth - outside of the
                rental time.
              </p>
            </li>
            <li>
              <p>
                Two Event Specialists will be on-site to operate the photo
                booth.
              </p>
            </li>
            <li>
              <p>
                Large selection of props - to enhance the fun and excitement.
              </p>
            </li>
            <li>
              <p>
                All high-resolution digital pictures will be provided after the
                event.
              </p>
            </li>
          </ul>
        </div>

        <hr className="reviewHr" />

        <div className="question">
          <p>
            I do not see a package that fits my event, can I customize a
            package?
          </p>
        </div>

        <div className="faqAnswer">
          <p>
            Yes. We can customize an entire package that will meet your needs in
            any way we can. <a href="/contactUs">Contact us</a> today for more
            information.
          </p>
        </div>

        <hr className="reviewHr" />
        <div className="question">
          <p>Will there be someone there to operate the photo booth?</p>
        </div>

        <div className="faqAnswer">
          <p>
            Yes. two fully trained event specialists will be available at your
            event to operate the booth as well as assist you and your guests if
            needed.
          </p>
        </div>

        <hr className="reviewHr" />
        <div className="question">
          <p>How far will you travel?</p>
        </div>

        <div className="faqAnswer">
          <p>
            We are based just north of Toronto; however, we will travel
            throughout Ontario to bring a portable photo booth to your special
            event. <a href="/contactUs">Contact us</a> today for a quote.
            Additional travel charges may apply.
          </p>
        </div>

        <hr className="reviewHr" />
        <div className="question">
          <p>
            Why would I need a photo booth if I already hired a photographer?
          </p>
        </div>

        <div className="faqAnswer">
          <p>
            Photographers do a remarkable job capturing the memories of your
            special day. However, Take My Photo will compliment your
            photographer. Our photo booth provides an element of fun and
            entertainment that a photographer may not be able to capture. Not
            only do we capture the candid moments of your event, our photo booth
            produces instant digital prints on-site for your guests to take with
            them.
          </p>
        </div>

        <hr className="reviewHr" />

        <div className="question">
          <p>How does the photobooth work?</p>
        </div>

        <div className="faqAnswer">
          <p>It’s easy as 1-2-3!</p>
          <div className="orderedAnswer">
            <ol>
              <li>Get Ready!</li>
              <li>Take the Photos!</li>
              <li>See the Results!</li>
            </ol>
            <ul>
              <li>gather your friends & family, grab some props.</li>
              <li>choose colour or black & white photos.</li>
              <li>
                in less than 15 seconds, it is the moment of truth - your prints
                are ready.
              </li>
            </ul>
          </div>
        </div>

        <hr className="reviewHr" />

        <div className="question">
          <p>Can the photo booth be set up outdoors?</p>
        </div>

        <div className="faqAnswer">
          <p>
            Yes. Our go anywhere photo booth can be used on a dock, a beach, or
            at any outdoor event provided there is a power outlet. Weather may
            also determine the outdoor usage.
          </p>
        </div>

        <hr className="reviewHr" />

        <div className="question">
          <p>How big is the photo booth?</p>
        </div>

        <div className="faqAnswer">
          <p>
            Our ultra-compact design is 16” deep x 22” wide x 72” high - packed
            with the latest DSLR camera and printer technology.
          </p>
        </div>

        <hr className="reviewHr" />

        <div className="question">
          <p>How much space do I need for the photo booth?</p>
        </div>

        <div className="faqAnswer">
          <p>
            The most common set up for the photo booth incorporates a bench and
            curtain backdrop. The recommended floor area required is
            approximately 8' by 8'.
          </p>
        </div>

        <hr className="reviewHr" />

        <div className="question">
          <p>What is the best location for the photo booth?</p>
        </div>

        <div className="faqAnswer">
          <p>
            The best location for the photo booth is in a high traffic area.
            This will guarantee our photo booth attracts the most attention and
            delivers the most fun and entertainment at your event.
          </p>
        </div>

        <hr className="reviewHr" />

        <div className="question">
          <p>How many people can fit in the picture?</p>
        </div>

        <div className="faqAnswer">
          <p>
            You are only limited to the number of people you can squeeze in
            front of the camera. Our photo booth is an open concept design;
            therefore, the group shots are easily accommodated. Our photo booth
            is wheelchair accessible.
          </p>
        </div>

        <hr className="reviewHr" />

        <div className="question">
          <p>How long does it take to set up the photo booth?</p>
        </div>

        <div className="faqAnswer">
          <p>
            Setting up the photo booth and making any necessary adjustments
            takes approximately 20 minutes. The take down process is
            approximately the same.
          </p>
        </div>

        <hr className="reviewHr" />

        <div className="question">
          <p>What size are the photos?</p>
        </div>

        <div className="faqAnswer">
          <p>
            We can produce two printed formats; two identical 2x6 photo strips
            or a single 4x6 print.{" "}
            <a href="/sampleLayouts">
              To view some of our sample layout options, click here
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
