import React from "react";
import { NavLink } from "react-router-dom";

import Booking from "./reusableComponents/Booking";

export default function Sitemap() {
  return (
    <>
      <div className="sitemapWindow wrapper">
        <div className="privacyTitle">
          <h2 className="pageTitle">Sitemap</h2>
        </div>

        <div className="sitemapContent">
          <ul className="sitemapLinks">
            <li>
              <NavLink to="">Home Page</NavLink>
            </li>
            <li>
              <NavLink to="/aboutUs">About Us</NavLink>
            </li>
            <li>
              <NavLink to="/prices">
                Photo Booth Rental Prices and Current Specials
              </NavLink>
            </li>
            <li>
              <NavLink to="/testimonials">Testimonials</NavLink>
            </li>
            <li>
              <NavLink to="/faq">Frequently Asked Questions</NavLink>
            </li>
            <li>
              <NavLink to="/contactUs">Contact Us</NavLink>
            </li>
            <li>
              <NavLink to="/samples">
                Sample Photo Print Layouts and Designs
              </NavLink>
            </li>
            <li>
              <NavLink to="/ourPromise">Our Promise and Our Commitment</NavLink>
            </li>
            <li>
              <NavLink to="/privacyPolicy">Privacy Policy</NavLink>
            </li>
            <li>
              <NavLink
                to="https://www.facebook.com/TakeMyPhoto.ca"
                target="_blank"
              >
                Facebook Fan Page
              </NavLink>
            </li>
            <li>
              <NavLink to="https://www.instagram.com/takemyphoto.ca">
                Instagram
              </NavLink>
            </li>
            <li>
              <NavLink to="https://www.weddingwire.ca/photobooth/take-my-photo--e7858/reviews">
                Wedding Wire Reviews
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="sitemapSubcontent">
          <p>
            A photo booth has always inspired excitement, spontaneity, and lots
            of fun. With the latest digital technology and ultra-compact design,
            our versatile photo booths are guaranteed to be a great addition to
            your next special event.
          </p>
          <p>
            Take My Photo is the most value added photo booth rental company in
            Ontario for good reason! We provide the best customer service and
            the most value well beyond the cost. Our head office is just north
            of Toronto, but don't worry, we will travel throughout Ontario to
            bring a photo booth to your special event. Give us a call and we
            will answer all your questions.
          </p>
          <p>
            Our fun and skilled event staff will be on site to ensure your
            guests enjoy a smooth and unforgettable experience! Your guests will
            have a good laugh, create memorable moments, and at the end of the
            event they will go home with a charming keepsake.
          </p>
        </div>

        <div className="sitemapRentalContainer">
          <div className="siteMapRentalContent">
            <h2>TMP Photo Booth Rentals Are Perfect For:</h2>
            <p>
              Weddings <span className="rentalLine">|</span> Corporate Events{" "}
              <span className="rentalLine">|</span> Graduations{" "}
              <span className="rentalLine">|</span> School Dances{" "}
              <span className="rentalLine">|</span> Birthday Parties{" "}
              <span className="rentalLine">|</span> Holiday Parties{" "}
              <span className="rentalLine">|</span> Bar Bitzvahs{" "}
              <span className="rentalLine">|</span> Bat Mitzvahs{" "}
              <span className="rentalLine">|</span> Charity Fundraisers{" "}
              <span className="rentalLine">|</span> Promotional Events
            </p>
          </div>
        </div>
      </div>
      <Booking
        title1="Book Your"
        highlight="Photo Booth"
        title2="Today!"
        bookingP="Contact us today for some great ideas on how we can make your next event more memorable with Take My Photo."
        link="/contactUs"
        buttonText="Contact Us Today!"
      />
    </>
  );
}
