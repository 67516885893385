import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import ContactInfo from "./reusableComponents/ContactInfo";
import SocialMedia from "./reusableComponents/SocialMedia";
import guaranteedBadge from "../media/The-Best-Guaranteed.png";
import weCareMore from "../media/we-care-more-about-your-event-200.png";

import "react-datepicker/dist/react-datepicker.css";

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Required";
  } else if (values.name.length > 30) {
    errors.name = "Name must be 30 characters or less";
  }

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.phone) {
    errors.phone = "Required";
  } else if (/^[+]?[0-9]{9,12}$/.test(values.phone)) {
    errors.phone = "Invalid phone number";
  }

  if (!values.dateOfEvent) {
    errors.dateOfEvent = "Please select a date";
  }
  if (!values.eventLocation) {
    errors.eventLocation = "Please enter event location";
  } else if (values.eventLocation.length > 30) {
    errors.eventLocation = "Event location must be 30 characters or less";
  }
  if (values.message.length > 140) {
    errors.message = "Max 140 characters";
  }
  return errors;
};

export default function Contact() {
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [contactForm, setContactForm] = useState({
    name: "",
    phone: "",
    email: "",
    dateOfEvent: "",
    eventLocation: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const FORM_ENDPOINT =
    "https://public.herotofu.com/v1/6dca8080-4744-11ee-b711-0fdc810d0d65";
  const [startDate, setStartDate] = useState(new Date());

  const updateDate = (date) => {
    setStartDate(date);
    setContactForm({
      ...contactForm,
      dateOfEvent: date.toString(),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(contactForm));
    setSubmitted(true);
  };
  useEffect(() => {
    const finishSubmit = () => {
      fetch(FORM_ENDPOINT, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(contactForm),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Form response was not ok");
          }
        })
        .catch((err) => {
          // Submit the form manually
        });
    };
    if (Object.keys(errors).length === 0 && submitted) {
      setSuccess(true);
      finishSubmit();
    } else {
      return;
    }
  }, [errors, submitted, contactForm]);
  if (success) {
    return navigate("/thankYou");
  }
  return (
    <div className="wrapper contactContainer">
      <div className="contactDeskFlex">
        <div className="contactDesk">
          <section>
            <h1 className="orangeBlackTitle">
              Contact <span className="highlightOrange">Info</span>
            </h1>
            <ContactInfo />
          </section>
          <section>
            <h2 className="orangeBlackTitle">
              Stay <span className="highlightOrange">Connected</span>
            </h2>
            <SocialMedia />
          </section>
          <div className="badgeImageContact">
            <img src={guaranteedBadge} alt="Guaranteed best service badge" />
            <img src={weCareMore} alt="We Care More about your event" />
          </div>
        </div>
        <div className="formContainer">
          <h2 className="orangeBlackTitle">
            Get <span className="highlightOrange">In Touch</span>
          </h2>
          <p>
            We are happy to answer any questions you may have about our photo
            booth rentals. Use the form below to contact us. We look forward to
            hearing from you.
          </p>
          <p className="bold">
            Please note that we do our best to reply to all inquires within 24
            hours. If you do not receive a reply, please check your bulk/junk
            mail folder.
          </p>
          <form
            onSubmit={handleSubmit}
            action={FORM_ENDPOINT}
            method="post"
            acceptCharset="UTF-8"
          >
            {Object.keys(errors).length !== 0 && (
              <div className="error">
                {errors.name && <p>{errors.name}</p>}
                {errors.phone && <p>{errors.phone}</p>}
                {errors.email && <p>{errors.email}</p>}
                {errors.dateOfEvent && <p>{errors.dateOfEvent}</p>}
                {errors.eventLocation && <p>{errors.eventLocation}</p>}
                {errors.message && <p>{errors.message}</p>}
              </div>
            )}
            <div className="formContentContainer">
              <h3>Contact Details:</h3>
              <div>
                <label className="visuallyHidden" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  onChange={(e) =>
                    setContactForm({
                      ...contactForm,
                      name: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div>
                <label className="visuallyHidden" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  onChange={(e) =>
                    setContactForm({
                      ...contactForm,
                      email: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div>
                <label className="visuallyHidden" htmlFor="phone">
                  Phone
                </label>
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  placeholder="Phone 000-000-0000"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  onChange={(e) =>
                    setContactForm({
                      ...contactForm,
                      phone: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
            <div className="formContentContainer">
              <h3>Tell Us About Your Event:</h3>
              <div>
                <label htmlFor="dateOfEvent">Date of Event: </label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => updateDate(date)}
                  className="datePicker"
                />
              </div>
              <div>
                <label className="visuallyHidden" htmlFor="eventLocation">
                  Event Location
                </label>
                <input
                  type="text"
                  name="eventLocation"
                  id="eventLocation"
                  placeholder="Event Location"
                  onChange={(e) =>
                    setContactForm({
                      ...contactForm,
                      eventLocation: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
            <div className="formContentContainer">
              <h3>Add a Message:</h3>
              <div>
                <label className="visuallyHidden" htmlFor="message">
                  Add a message
                </label>
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="5"
                  onChange={(e) =>
                    setContactForm({
                      ...contactForm,
                      message: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
            <button type="submit" className="purpleButton">
              Submit My Request
            </button>
            <div
              style={{
                textIndent: -99999,
                whiteSpace: "nowrap",
                overflow: "hidden",
                position: "absolute",
              }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="_gotcha"
                tabIndex="-1"
                autoComplete="off"
              />
            </div>
          </form>
        </div>
      </div>
      <div className="contactBottom">
        <p>
          A photo booth has always inspired excitement, spontaneity, and lots of
          fun. With the latest digital technology and ultra-compact design, our
          versatile photo booths are guaranteed to be a great addition to your
          next special event.
        </p>
        <p>
          Take My Photo is the most value added photo booth rental company in
          Ontario for good reason! We provide the best customer service and the
          most value well beyond the cost. Our head office is just north of
          Toronto, but don’t worry, we will travel throughout Ontario to bring a
          photo booth to your special event. Give us a call and we will answer
          all your questions.
        </p>
        <p>
          Our fun and skilled event staff will be on site to ensure your guests
          enjoy a smooth and unforgettable experience! Your guests will have a
          good laugh, create memorable moments, and at the end of the event they
          will go home with a charming keepsake.
        </p>
      </div>
    </div>
  );
}
