import React, { useState, useEffect } from "react";
import { BsFillTriangleFill } from "react-icons/bs";

export default function GoToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const handleVisible = () => {
    const windowScroll = document.documentElement.scrollTop;
    if (windowScroll > 300) {
      setIsVisible(true);
    } else if (windowScroll <= 300) {
      setIsVisible(false);
    }
  };

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleVisible);
    return () => {
      window.removeEventListener("scroll", handleVisible);
    };
  }, []);
  return (
    <>
      {isVisible && (
        <button
          onClick={handleBackToTop}
          className="upArrowContainer"
          aria-label="Go back to top"
        >
          <BsFillTriangleFill className="upArrow" />
        </button>
      )}
    </>
  );
}
