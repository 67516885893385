import React from "react";
import { NavLink } from "react-router-dom";

export default function Booking({
  title1,
  highlight,
  title2,
  bookingP,
  link,
  additional,
  buttonText,
}) {
  return (
    <div className="bookPhoto">
      <h2>
        {title1} <span className="highlight">{highlight}</span> {title2}
      </h2>
      <p>{bookingP}</p>
      {additional}
      <button className="purpleButton">
        <NavLink to={link}>{buttonText}</NavLink>
      </button>
    </div>
  );
}
