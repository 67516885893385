import React from "react";
import LayoutTemplateHero from "./LayoutTemplateHero";

import layout from "../../media/Laptop.png";

// Wedding Images
import w1 from "../../media/layout/2x6/wedding/photo-booth-rental-design-1.jpg";
import w2 from "../../media/layout/2x6/wedding/photo-booth-rental-design-2.jpg";
import w3 from "../../media/layout/2x6/wedding/photo-booth-rental-design-3.jpg";
import w4 from "../../media/layout/2x6/wedding/photo-booth-rental-design-4.jpg";
import w5 from "../../media/layout/2x6/wedding/photo-booth-rental-design-5.jpg";
import w6 from "../../media/layout/2x6/wedding/photo-booth-rental-design-6.jpg";
import w7 from "../../media/layout/2x6/wedding/photo-booth-rental-design-7.jpg";
import w8 from "../../media/layout/2x6/wedding/photo-booth-rental-design-8.jpg";
import w9 from "../../media/layout/2x6/wedding/photo-booth-rental-design-9.jpg";
import w10 from "../../media/layout/2x6/wedding/photo-booth-rental-design-10.jpg";
import w11 from "../../media/layout/2x6/wedding/photo-booth-rental-design-11.jpg";
import w12 from "../../media/layout/2x6/wedding/photo-booth-rental-design-12.jpg";
import w13 from "../../media/layout/2x6/wedding/photo-booth-rental-design-13.jpg";
import w14 from "../../media/layout/2x6/wedding/photo-booth-rental-design-14.jpg";
import w15 from "../../media/layout/2x6/wedding/photo-booth-rental-design-15.jpg";
import w16 from "../../media/layout/2x6/wedding/photo-booth-rental-design-16.jpg";
import w17 from "../../media/layout/2x6/wedding/photo-booth-rental-design-17.jpg";
import w18 from "../../media/layout/2x6/wedding/photo-booth-rental-design-18.jpg";
import w19 from "../../media/layout/2x6/wedding/photo-booth-rental-design-19.jpg";
import w20 from "../../media/layout/2x6/wedding/photo-booth-rental-design-20.jpg";
import w21 from "../../media/layout/2x6/wedding/photo-booth-rental-design-21.jpg";
import w22 from "../../media/layout/2x6/wedding/photo-booth-rental-design-22.jpg";
import w23 from "../../media/layout/2x6/wedding/photo-booth-rental-design-23.jpg";
import w24 from "../../media/layout/2x6/wedding/photo-booth-rental-design-24.jpg";
import w25 from "../../media/layout/2x6/wedding/photo-booth-rental-design-25.jpg";
import w26 from "../../media/layout/2x6/wedding/photo-booth-rental-design-26.jpg";
import w27 from "../../media/layout/2x6/wedding/photo-booth-rental-design-27.jpg";
import w28 from "../../media/layout/2x6/wedding/photo-booth-rental-design-28.jpg";
import w29 from "../../media/layout/2x6/wedding/photo-booth-rental-design-29.jpg";
import w30 from "../../media/layout/2x6/wedding/photo-booth-rental-design-30.jpg";
import w31 from "../../media/layout/2x6/wedding/photo-booth-rental-design-31.jpg";
import w32 from "../../media/layout/2x6/wedding/photo-booth-rental-design-32.jpg";
import w33 from "../../media/layout/2x6/wedding/photo-booth-rental-design-33.jpg";
import w34 from "../../media/layout/2x6/wedding/photo-booth-rental-design-34.jpg";
import w35 from "../../media/layout/2x6/wedding/photo-booth-rental-design-35.jpg";
import w36 from "../../media/layout/2x6/wedding/photo-booth-rental-design-36.jpg";
import w37 from "../../media/layout/2x6/wedding/photo-booth-rental-design-37.jpg";
import w38 from "../../media/layout/2x6/wedding/photo-booth-rental-design-38.jpg";
import w39 from "../../media/layout/2x6/wedding/photo-booth-rental-design-39.jpg";
import w40 from "../../media/layout/2x6/wedding/photo-booth-rental-design-40.jpg";
import w41 from "../../media/layout/2x6/wedding/photo-booth-rental-design-41.jpg";
import w42 from "../../media/layout/2x6/wedding/photo-booth-rental-design-42.jpg";
import w43 from "../../media/layout/2x6/wedding/photo-booth-rental-design-43.jpg";
import w44 from "../../media/layout/2x6/wedding/photo-booth-rental-design-44.jpg";
import w45 from "../../media/layout/2x6/wedding/photo-booth-rental-design-45.jpg";
import w46 from "../../media/layout/2x6/wedding/photo-booth-rental-design-46.jpg";
import w47 from "../../media/layout/2x6/wedding/photo-booth-rental-design-47.jpg";
import w48 from "../../media/layout/2x6/wedding/photo-booth-rental-design-48.jpg";
import w49 from "../../media/layout/2x6/wedding/photo-booth-rental-design-49.jpg";
import w50 from "../../media/layout/2x6/wedding/photo-booth-rental-design-50.jpg";
import w51 from "../../media/layout/2x6/wedding/photo-booth-rental-design-51.jpg";
import w52 from "../../media/layout/2x6/wedding/photo-booth-rental-design-52.jpg";
import w53 from "../../media/layout/2x6/wedding/photo-booth-rental-design-53.jpg";
import w54 from "../../media/layout/2x6/wedding/photo-booth-rental-design-54.jpg";
import w55 from "../../media/layout/2x6/wedding/photo-booth-rental-design-55.jpg";
import w56 from "../../media/layout/2x6/wedding/photo-booth-rental-design-56.jpg";
import w57 from "../../media/layout/2x6/wedding/photo-booth-rental-design-57.jpg";
import w58 from "../../media/layout/2x6/wedding/photo-booth-rental-design-58.jpg";
import w59 from "../../media/layout/2x6/wedding/photo-booth-rental-design-59.jpg";
import w60 from "../../media/layout/2x6/wedding/photo-booth-rental-design-60.jpg";
import w61 from "../../media/layout/2x6/wedding/photo-booth-rental-design-61.jpg";
import w62 from "../../media/layout/2x6/wedding/photo-booth-rental-design-62.jpg";
import w63 from "../../media/layout/2x6/wedding/photo-booth-rental-design-63.jpg";
import w64 from "../../media/layout/2x6/wedding/photo-booth-rental-design-64.jpg";
import w65 from "../../media/layout/2x6/wedding/photo-booth-rental-design-65.jpg";
import w66 from "../../media/layout/2x6/wedding/photo-booth-rental-design-66.jpg";
import w67 from "../../media/layout/2x6/wedding/photo-booth-rental-design-67.jpg";
import w68 from "../../media/layout/2x6/wedding/photo-booth-rental-design-68.jpg";
import w69 from "../../media/layout/2x6/wedding/photo-booth-rental-design-69.jpg";
import w70 from "../../media/layout/2x6/wedding/photo-booth-rental-design-70.jpg";
import w71 from "../../media/layout/2x6/wedding/photo-booth-rental-design-71.jpg";
import w72 from "../../media/layout/2x6/wedding/photo-booth-rental-design-72.jpg";
import w73 from "../../media/layout/2x6/wedding/photo-booth-rental-design-73.jpg";
import w74 from "../../media/layout/2x6/wedding/photo-booth-rental-design-74.jpg";
import w75 from "../../media/layout/2x6/wedding/photo-booth-rental-design-75.jpg";
import w76 from "../../media/layout/2x6/wedding/photo-booth-rental-design-76.jpg";
import w77 from "../../media/layout/2x6/wedding/photo-booth-rental-design-77.jpg";
import w78 from "../../media/layout/2x6/wedding/photo-booth-rental-design-78.jpg";
import w79 from "../../media/layout/2x6/wedding/photo-booth-rental-design-79.jpg";
import w80 from "../../media/layout/2x6/wedding/photo-booth-rental-design-80.jpg";
import w81 from "../../media/layout/2x6/wedding/photo-booth-rental-design-81.jpg";
import w82 from "../../media/layout/2x6/wedding/photo-booth-rental-design-82.jpg";
import w83 from "../../media/layout/2x6/wedding/photo-booth-rental-design-83.jpg";
import w84 from "../../media/layout/2x6/wedding/photo-booth-rental-design-84.jpg";
import w85 from "../../media/layout/2x6/wedding/photo-booth-rental-design-85.jpg";
import w86 from "../../media/layout/2x6/wedding/photo-booth-rental-design-86.jpg";
import w87 from "../../media/layout/2x6/wedding/photo-booth-rental-design-87.jpg";
import w88 from "../../media/layout/2x6/wedding/photo-booth-rental-design-88.jpg";
import w89 from "../../media/layout/2x6/wedding/photo-booth-rental-design-89.jpg";
import w90 from "../../media/layout/2x6/wedding/photo-booth-rental-design-90.jpg";
import w91 from "../../media/layout/2x6/wedding/photo-booth-rental-design-91.jpg";
import w92 from "../../media/layout/2x6/wedding/photo-booth-rental-design-92.jpg";
import w93 from "../../media/layout/2x6/wedding/photo-booth-rental-design-93.jpg";
import w94 from "../../media/layout/2x6/wedding/photo-booth-rental-design-94.jpg";
import w95 from "../../media/layout/2x6/wedding/photo-booth-rental-design-95.jpg";
import w96 from "../../media/layout/2x6/wedding/photo-booth-rental-design-96.jpg";
import w97 from "../../media/layout/2x6/wedding/photo-booth-rental-design-97.jpg";
import w98 from "../../media/layout/2x6/wedding/photo-booth-rental-design-98.jpg";
import w99 from "../../media/layout/2x6/wedding/photo-booth-rental-design-99.jpg";
import w100 from "../../media/layout/2x6/wedding/photo-booth-rental-design-100.jpg";
import w101 from "../../media/layout/2x6/wedding/photo-booth-rental-design-101.jpg";
import w102 from "../../media/layout/2x6/wedding/photo-booth-rental-design-102.jpg";
import w103 from "../../media/layout/2x6/wedding/photo-booth-rental-design-103.jpg";
import w104 from "../../media/layout/2x6/wedding/photo-booth-rental-design-104.jpg";
import w105 from "../../media/layout/2x6/wedding/photo-booth-rental-design-105.jpg";
import w106 from "../../media/layout/2x6/wedding/photo-booth-rental-design-106.jpg";
import w107 from "../../media/layout/2x6/wedding/photo-booth-rental-design-107.jpg";
import w108 from "../../media/layout/2x6/wedding/photo-booth-rental-design-108.jpg";
import w109 from "../../media/layout/2x6/wedding/photo-booth-rental-design-109.jpg";
import w110 from "../../media/layout/2x6/wedding/photo-booth-rental-design-110.jpg";
import w111 from "../../media/layout/2x6/wedding/photo-booth-rental-design-111.jpg";
import w112 from "../../media/layout/2x6/wedding/photo-booth-rental-design-112.jpg";
import w113 from "../../media/layout/2x6/wedding/photo-booth-rental-design-113.jpg";
import w114 from "../../media/layout/2x6/wedding/photo-booth-rental-design-114.jpg";
import w115 from "../../media/layout/2x6/wedding/photo-booth-rental-design-115.jpg";
import W1 from "../../media/layout/4x6/wedding/wedding-photo-booth-rental-4x6-design-1.jpg";
import W2 from "../../media/layout/4x6/wedding/wedding-photo-booth-rental-4x6-design-2.jpg";
import W3 from "../../media/layout/4x6/wedding/wedding-photo-booth-rental-4x6-design-3.jpg";
import W4 from "../../media/layout/4x6/wedding/wedding-photo-booth-rental-4x6-design-4.jpg";
import W5 from "../../media/layout/4x6/wedding/wedding-photo-booth-rental-4x6-design-5.jpg";
import W6 from "../../media/layout/4x6/wedding/wedding-photo-booth-rental-4x6-design-6.jpg";
import W7 from "../../media/layout/4x6/wedding/wedding-photo-booth-rental-4x6-design-7.jpg";
import W8 from "../../media/layout/4x6/wedding/wedding-photo-booth-rental-4x6-design-8.jpg";
import W9 from "../../media/layout/4x6/wedding/wedding-photo-booth-rental-4x6-design-9.jpg";
import W10 from "../../media/layout/4x6/wedding/wedding-photo-booth-rental-4x6-design-10.jpg";
import W11 from "../../media/layout/4x6/wedding/wedding-photo-booth-rental-4x6-design-11.jpg";
import W12 from "../../media/layout/4x6/wedding/wedding-photo-booth-rental-4x6-design-12.jpg";
import W13 from "../../media/layout/4x6/wedding/wedding-photo-booth-rental-4x6-design-13.jpg";
import W14 from "../../media/layout/4x6/wedding/wedding-photo-booth-rental-4x6-design-14.jpg";
import W15 from "../../media/layout/4x6/wedding/wedding-photo-booth-rental-4x6-design-15.jpg";

// Birthday Images
import b1 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-1.jpg";
import b2 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-2.jpg";
import b3 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-3.jpg";
import b4 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-4.jpg";
import b5 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-5.jpg";
import b6 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-6.jpg";
import b7 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-7.jpg";
import b8 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-8.jpg";
import b9 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-9.jpg";
import b10 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-10.jpg";
import b11 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-11.jpg";
import b12 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-12.jpg";
import b13 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-13.jpg";
import b14 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-14.jpg";
import b15 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-15.jpg";
import b16 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-16.jpg";
import b17 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-17.jpg";
import b18 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-18.jpg";
import b19 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-19.jpg";
import b20 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-20.jpg";
import b21 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-21.jpg";
import b22 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-22.jpg";
import b23 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-23.jpg";
import b24 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-24.jpg";
import b25 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-25.jpg";
import b26 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-26.jpg";
import b27 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-27.jpg";
import b28 from "../../media/layout/2x6/birthday/birthday-party-photo-booth-rental-design-28.jpg";
import B1 from "../../media/layout/4x6/birthday/birthday-party-photo-booth-rental-4x6-design-1.jpg";
import B2 from "../../media/layout/4x6/birthday/birthday-party-photo-booth-rental-4x6-design-2.jpg";
import B3 from "../../media/layout/4x6/birthday/birthday-party-photo-booth-rental-4x6-design-3.jpg";
import B4 from "../../media/layout/4x6/birthday/birthday-party-photo-booth-rental-4x6-design-4.jpg";
import B5 from "../../media/layout/4x6/birthday/birthday-party-photo-booth-rental-4x6-design-5.jpg";
import B6 from "../../media/layout/4x6/birthday/birthday-party-photo-booth-rental-4x6-design-6.jpg";
import B7 from "../../media/layout/4x6/birthday/birthday-party-photo-booth-rental-4x6-design-7.jpg";
import B8 from "../../media/layout/4x6/birthday/birthday-party-photo-booth-rental-4x6-design-8.jpg";

// Corporate Images
import c1 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-1.jpg";
import c2 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-2.jpg";
import c3 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-3.jpg";
import c4 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-4.jpg";
import c5 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-5.jpg";
import c6 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-6.jpg";
import c7 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-7.jpg";
import c8 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-8.jpg";
import c9 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-9.jpg";
import c10 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-10.jpg";
import c11 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-11.jpg";
import c12 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-12.jpg";
import c13 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-13.jpg";
import c14 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-14.jpg";
import c15 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-15.jpg";
import c16 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-16.jpg";
import c17 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-17.jpg";
import c18 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-18.jpg";
import c19 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-19.jpg";
import c20 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-20.jpg";
import c21 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-21.jpg";
import c22 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-22.jpg";
import c23 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-23.jpg";
import c24 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-24.jpg";
import c25 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-25.jpg";
import c26 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-26.jpg";
import c27 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-27.jpg";
import c28 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-28.jpg";
import c29 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-29.jpg";
import c30 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-30.jpg";
import c31 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-31.jpg";
import c32 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-32.jpg";
import c33 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-33.jpg";
import c34 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-34.jpg";
import c35 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-35.jpg";
import c36 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-36.jpg";
import c37 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-37.jpg";
import c38 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-38.jpg";
import c39 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-39.jpg";
import c40 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-40.jpg";
import c41 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-41.jpg";
import c42 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-42.jpg";
import c43 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-43.jpg";
import c44 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-44.jpg";
import c45 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-45.jpg";
import c46 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-46.jpg";
import c47 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-47.jpg";
import c48 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-48.jpg";
import c49 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-49.jpg";
import c50 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-50.jpg";
import c51 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-51.jpg";
import c52 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-52.jpg";
import c53 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-53.jpg";
import c54 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-54.jpg";
import c55 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-55.jpg";
import c56 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-56.jpg";
import c57 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-57.jpg";
import c58 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-58.jpg";
import c59 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-59.jpg";
import c60 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-60.jpg";
import c61 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-61.jpg";
import c62 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-62.jpg";
import c63 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-63.jpg";
import c64 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-64.jpg";
import c65 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-65.jpg";
import c66 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-66.jpg";
import c67 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-67.jpg";
import c68 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-68.jpg";
import c69 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-69.jpg";
import c70 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-70.jpg";
import c71 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-71.jpg";
import c72 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-72.jpg";
import c73 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-73.jpg";
import c74 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-74.jpg";
import c75 from "../../media/layout/2x6/corporate/corporate-photo-booth-rental-2x6-design-75.jpg";
import c76 from "../../media/layout/2x6/corporate/horizontal-corporate-photo-booth-rental-2x6-design-1.jpg";
import c77 from "../../media/layout/2x6/corporate/horizontal-corporate-photo-booth-rental-2x6-design-2.jpg";
import c78 from "../../media/layout/2x6/corporate/horizontal-corporate-photo-booth-rental-2x6-design-3.jpg";
import c79 from "../../media/layout/2x6/corporate/horizontal-corporate-photo-booth-rental-2x6-design-4.jpg";
import C1 from "../../media/layout/4x6/corporate/corporate-photo-booth-rental-4x6-design-1.jpg";
import C2 from "../../media/layout/4x6/corporate/corporate-photo-booth-rental-4x6-design-2.jpg";
import C3 from "../../media/layout/4x6/corporate/corporate-photo-booth-rental-4x6-design-3.jpg";
import C4 from "../../media/layout/4x6/corporate/corporate-photo-booth-rental-4x6-design-4.jpg";
import C5 from "../../media/layout/4x6/corporate/corporate-photo-booth-rental-4x6-design-5.jpg";
import C6 from "../../media/layout/4x6/corporate/corporate-photo-booth-rental-4x6-design-6.jpg";
import C7 from "../../media/layout/4x6/corporate/corporate-photo-booth-rental-4x6-design-7.jpg";
import C8 from "../../media/layout/4x6/corporate/corporate-photo-booth-rental-4x6-design-8.jpg";
import C9 from "../../media/layout/4x6/corporate/corporate-photo-booth-rental-4x6-design-9.jpg";
import C10 from "../../media/layout/4x6/corporate/corporate-photo-booth-rental-4x6-design-10.jpg";
import C11 from "../../media/layout/4x6/corporate/corporate-photo-booth-rental-4x6-design-11.jpg";
import C12 from "../../media/layout/4x6/corporate/corporate-photo-booth-rental-4x6-design-12.jpg";
import C13 from "../../media/layout/4x6/corporate/corporate-photo-booth-rental-4x6-design-13.jpg";
import C14 from "../../media/layout/4x6/corporate/corporate-photo-booth-rental-4x6-design-14.jpg";
import C15 from "../../media/layout/4x6/corporate/corporate-photo-booth-rental-4x6-design-15.jpg";
import C16 from "../../media/layout/4x6/corporate/corporate-photo-booth-rental-4x6-design-16.jpg";
import C17 from "../../media/layout/4x6/corporate/corporate-photo-booth-rental-4x6-design-17.jpg";
import C18 from "../../media/layout/4x6/corporate/corporate-photo-booth-rental-4x6-design-18.jpg";
import C19 from "../../media/layout/4x6/corporate/corporate-photo-booth-rental-4x6-design-19.jpg";
import C20 from "../../media/layout/4x6/corporate/corporate-photo-booth-rental-4x6-design-20.jpg";
import C21 from "../../media/layout/4x6/corporate/corporate-photo-booth-rental-4x6-design-21.jpg";

// Holiday Image
import h1 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-1.jpg";
import h2 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-2.jpg";
import h3 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-3.jpg";
import h4 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-4.jpg";
import h5 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-5.jpg";
import h6 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-6.jpg";
import h7 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-7.jpg";
import h8 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-8.jpg";
import h9 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-9.jpg";
import h10 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-10.jpg";
import h11 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-11.jpg";
import h12 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-12.jpg";
import h13 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-13.jpg";
import h14 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-14.jpg";
import h15 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-15.jpg";
import h16 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-16.jpg";
import h17 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-17.jpg";
import h18 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-18.jpg";
import h19 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-19.jpg";
import h20 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-20.jpg";
import h21 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-21.jpg";
import h22 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-22.jpg";
import h23 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-23.jpg";
import h24 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-24.jpg";
import h25 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-25.jpg";
import h26 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-26.jpg";
import h27 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-27.jpg";
import h28 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-28.jpg";
import h29 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-29.jpg";
import h30 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-30.jpg";
import h31 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-31.jpg";
import h32 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-32.jpg";
import h33 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-33.jpg";
import h34 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-34.jpg";
import h35 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-35.jpg";
import h36 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-36.jpg";
import h37 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-37.jpg";
import h38 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-38.jpg";
import h39 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-39.jpg";
import h40 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-40.jpg";
import h41 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-41.jpg";
import h42 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-42.jpg";
import h43 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-43.jpg";
import h44 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-44.jpg";
import h45 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-45.jpg";
import h46 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-46.jpg";
import h47 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-47.jpg";
import h48 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-48.jpg";
import h49 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-49.jpg";
import h50 from "../../media/layout/2x6/holiday/holiday-event-photo-booth-rental-design-50.jpg";
import H1 from "../../media/layout/4x6/holiday/holiday-photo-booth-rental-4x6-design-1.jpg";
import H2 from "../../media/layout/4x6/holiday/holiday-photo-booth-rental-4x6-design-2.jpg";
import H3 from "../../media/layout/4x6/holiday/holiday-photo-booth-rental-4x6-design-3.jpg";
import H4 from "../../media/layout/4x6/holiday/holiday-photo-booth-rental-4x6-design-4.jpg";
import H5 from "../../media/layout/4x6/holiday/holiday-photo-booth-rental-4x6-design-5.jpg";

// School Image
import s1 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-1.jpg";
import s2 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-2.jpg";
import s3 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-3.jpg";
import s4 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-4.jpg";
import s5 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-5.jpg";
import s6 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-6.jpg";
import s7 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-7.jpg";
import s8 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-8.jpg";
import s9 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-9.jpg";
import s10 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-10.jpg";
import s11 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-11.jpg";
import s12 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-12.jpg";
import s13 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-13.jpg";
import s14 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-14.jpg";
import s15 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-15.jpg";
import s16 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-16.jpg";
import s17 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-17.jpg";
import s18 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-18.jpg";
import s19 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-19.jpg";
import s20 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-20.jpg";
import s21 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-21.jpg";
import s22 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-22.jpg";
import s23 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-23.jpg";
import s24 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-24.jpg";
import s25 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-25.jpg";
import s26 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-26.jpg";
import s27 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-27.jpg";
import s28 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-28.jpg";
import s29 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-29.jpg";
import s30 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-30.jpg";
import s31 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-31.jpg";
import s32 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-32.jpg";
import s33 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-33.jpg";
import s34 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-34.jpg";
import s35 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-35.jpg";
import s36 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-36.jpg";
import s37 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-37.jpg";
import s38 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-38.jpg";
import s39 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-39.jpg";
import s40 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-40.jpg";
import s41 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-41.jpg";
import s42 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-42.jpg";
import s43 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-43.jpg";
import s44 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-44.jpg";
import s45 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-45.jpg";
import s46 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-46.jpg";
import s47 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-47.jpg";
import s48 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-48.jpg";
import s49 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-49.jpg";
import s50 from "../../media/layout/2x6/school/school-event-photo-booth-rental-design-50.jpg";

// Other Image
import o1 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-1.jpg";
import o2 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-2.jpg";
import o3 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-3.jpg";
import o4 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-4.jpg";
import o5 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-5.jpg";
import o6 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-6.jpg";
import o7 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-7.jpg";
import o8 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-8.jpg";
import o9 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-9.jpg";
import o10 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-10.jpg";
import o11 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-11.jpg";
import o12 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-12.jpg";
import o13 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-13.jpg";
import o14 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-14.jpg";
import o15 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-15.jpg";
import o16 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-16.jpg";
import o17 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-17.jpg";
import o18 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-18.jpg";
import o19 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-19.jpg";
import o20 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-20.jpg";
import o21 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-21.jpg";
import o22 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-22.jpg";
import o23 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-23.jpg";
import o24 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-24.jpg";
import o25 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-25.jpg";
import o26 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-26.jpg";
import o27 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-27.jpg";
import o28 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-28.jpg";
import o29 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-29.jpg";
import o30 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-30.jpg";
import o31 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-31.jpg";
import o32 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-32.jpg";
import o33 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-33.jpg";
import o34 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-34.jpg";
import o35 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-35.jpg";
import o36 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-36.jpg";
import o37 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-37.jpg";
import o38 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-38.jpg";
import o39 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-39.jpg";
import o40 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-40.jpg";
import o41 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-41.jpg";
import o42 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-42.jpg";
import o43 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-43.jpg";
import o44 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-44.jpg";
import o45 from "../../media/layout/2x6/other/photo-booth-rental-2x6-design-45.jpg";
import O1 from "../../media/layout/4x6/other/photo-booth-rental-4x6-design-1.jpg";
import O2 from "../../media/layout/4x6/other/photo-booth-rental-4x6-design-2.jpg";
import O3 from "../../media/layout/4x6/other/photo-booth-rental-4x6-design-3.jpg";
import O4 from "../../media/layout/4x6/other/photo-booth-rental-4x6-design-4.jpg";
import O5 from "../../media/layout/4x6/other/photo-booth-rental-4x6-design-5.jpg";
import O6 from "../../media/layout/4x6/other/photo-booth-rental-4x6-design-6.jpg";
import Booking from "../reusableComponents/Booking";
export default function Layout() {
  const weddingSizeOneArray = [
    { class: "sizeOne", img: w1, alt: "Wedding Sample	1" },
    { class: "sizeOne", img: w2, alt: "Wedding Sample	2" },
    { class: "sizeOne", img: w3, alt: "Wedding Sample	3" },
    { class: "sizeOne", img: w4, alt: "Wedding Sample	4" },
    { class: "sizeOne", img: w5, alt: "Wedding Sample	5" },
    { class: "sizeOne", img: w6, alt: "Wedding Sample	6" },
    { class: "sizeOne", img: w7, alt: "Wedding Sample	7" },
    { class: "sizeOne", img: w8, alt: "Wedding Sample	8" },
    { class: "sizeOne", img: w9, alt: "Wedding Sample	9" },
    { class: "sizeOne", img: w10, alt: "Wedding Sample 10	" },
    { class: "sizeOne", img: w11, alt: "Wedding Sample 11	" },
    { class: "sizeOne", img: w12, alt: "Wedding Sample 12	" },
    { class: "sizeOne", img: w13, alt: "Wedding Sample 13	" },
    { class: "sizeOne", img: w14, alt: "Wedding Sample 14	" },
    { class: "sizeOne", img: w15, alt: "Wedding Sample 15	" },
    { class: "sizeOne", img: w16, alt: "Wedding Sample 16	" },
    { class: "sizeOne", img: w17, alt: "Wedding Sample 17	" },
    { class: "sizeOne", img: w18, alt: "Wedding Sample 18	" },
    { class: "sizeOne", img: w19, alt: "Wedding Sample 19	" },
    { class: "sizeOne", img: w20, alt: "Wedding Sample 20	" },
    { class: "sizeOne", img: w21, alt: "Wedding Sample 21	" },
    { class: "sizeOne", img: w22, alt: "Wedding Sample 22	" },
    { class: "sizeOne", img: w23, alt: "Wedding Sample 23	" },
    { class: "sizeOne", img: w24, alt: "Wedding Sample 24	" },
    { class: "sizeOne", img: w25, alt: "Wedding Sample 25	" },
    { class: "sizeOne", img: w26, alt: "Wedding Sample 26	" },
    { class: "sizeOne", img: w27, alt: "Wedding Sample 27	" },
    { class: "sizeOne", img: w28, alt: "Wedding Sample 28	" },
    { class: "sizeOne", img: w29, alt: "Wedding Sample 29	" },
    { class: "sizeOne", img: w30, alt: "Wedding Sample 30	" },
    { class: "sizeOne", img: w31, alt: "Wedding Sample 31	" },
    { class: "sizeOne", img: w32, alt: "Wedding Sample 32	" },
    { class: "sizeOne", img: w33, alt: "Wedding Sample 33	" },
    { class: "sizeOne", img: w34, alt: "Wedding Sample 34	" },
    { class: "sizeOne", img: w35, alt: "Wedding Sample 35	" },
    { class: "sizeOne", img: w36, alt: "Wedding Sample 36	" },
    { class: "sizeOne", img: w37, alt: "Wedding Sample 37	" },
    { class: "sizeOne", img: w38, alt: "Wedding Sample 38	" },
    { class: "sizeOne", img: w39, alt: "Wedding Sample 39	" },
    { class: "sizeOne", img: w40, alt: "Wedding Sample 40	" },
    { class: "sizeOne", img: w41, alt: "Wedding Sample 41	" },
    { class: "sizeOne", img: w42, alt: "Wedding Sample 42	" },
    { class: "sizeOne", img: w43, alt: "Wedding Sample 43	" },
    { class: "sizeOne", img: w44, alt: "Wedding Sample 44	" },
    { class: "sizeOne", img: w45, alt: "Wedding Sample 45	" },
    { class: "sizeOne", img: w46, alt: "Wedding Sample 46	" },
    { class: "sizeOne", img: w47, alt: "Wedding Sample 47	" },
    { class: "sizeOne", img: w48, alt: "Wedding Sample 48	" },
    { class: "sizeOne", img: w49, alt: "Wedding Sample 49	" },
    { class: "sizeOne", img: w50, alt: "Wedding Sample 50	" },
    { class: "sizeOne", img: w51, alt: "Wedding Sample 51	" },
    { class: "sizeOne", img: w52, alt: "Wedding Sample 52	" },
    { class: "sizeOne", img: w53, alt: "Wedding Sample 53	" },
    { class: "sizeOne", img: w54, alt: "Wedding Sample 54	" },
    { class: "sizeOne", img: w55, alt: "Wedding Sample 55	" },
    { class: "sizeOne", img: w56, alt: "Wedding Sample 56	" },
    { class: "sizeOne", img: w57, alt: "Wedding Sample 57	" },
    { class: "sizeOne", img: w58, alt: "Wedding Sample 58	" },
    { class: "sizeOne", img: w59, alt: "Wedding Sample 59	" },
    { class: "sizeOne", img: w60, alt: "Wedding Sample 60	" },
    { class: "sizeOne", img: w61, alt: "Wedding Sample 61	" },
    { class: "sizeOne", img: w62, alt: "Wedding Sample 62	" },
    { class: "sizeOne", img: w63, alt: "Wedding Sample 63	" },
    { class: "sizeOne", img: w64, alt: "Wedding Sample 64	" },
    { class: "sizeOne", img: w65, alt: "Wedding Sample 65	" },
    { class: "sizeOne", img: w66, alt: "Wedding Sample 66	" },
    { class: "sizeOne", img: w67, alt: "Wedding Sample 67	" },
    { class: "sizeOne", img: w68, alt: "Wedding Sample 68	" },
    { class: "sizeOne", img: w69, alt: "Wedding Sample 69	" },
    { class: "sizeOne", img: w70, alt: "Wedding Sample 70	" },
    { class: "sizeOne", img: w71, alt: "Wedding Sample 71	" },
    { class: "sizeOne", img: w72, alt: "Wedding Sample 72	" },
    { class: "sizeOne", img: w73, alt: "Wedding Sample 73	" },
    { class: "sizeOne", img: w74, alt: "Wedding Sample 74	" },
    { class: "sizeOne", img: w75, alt: "Wedding Sample 75	" },
    { class: "sizeOne", img: w76, alt: "Wedding Sample 76	" },
    { class: "sizeOne", img: w77, alt: "Wedding Sample 77	" },
    { class: "sizeOne", img: w78, alt: "Wedding Sample 78	" },
    { class: "sizeOne", img: w79, alt: "Wedding Sample 79	" },
    { class: "sizeOne", img: w80, alt: "Wedding Sample 80	" },
    { class: "sizeOne", img: w81, alt: "Wedding Sample 81	" },
    { class: "sizeOne", img: w82, alt: "Wedding Sample 82	" },
    { class: "sizeOne", img: w83, alt: "Wedding Sample 83	" },
    { class: "sizeOne", img: w84, alt: "Wedding Sample 84	" },
    { class: "sizeOne", img: w85, alt: "Wedding Sample 85	" },
    { class: "sizeOne", img: w86, alt: "Wedding Sample 86	" },
    { class: "sizeOne", img: w87, alt: "Wedding Sample 87	" },
    { class: "sizeOne", img: w88, alt: "Wedding Sample 88	" },
    { class: "sizeOne", img: w89, alt: "Wedding Sample 89	" },
    { class: "sizeOne", img: w90, alt: "Wedding Sample 90	" },
    { class: "sizeOne", img: w91, alt: "Wedding Sample 91	" },
    { class: "sizeOne", img: w92, alt: "Wedding Sample 92	" },
    { class: "sizeOne", img: w93, alt: "Wedding Sample 93	" },
    { class: "sizeOne", img: w94, alt: "Wedding Sample 94	" },
    { class: "sizeOne", img: w95, alt: "Wedding Sample 95	" },
    { class: "sizeOne", img: w96, alt: "Wedding Sample 96	" },
    { class: "sizeOne", img: w97, alt: "Wedding Sample 97	" },
    { class: "sizeOne", img: w98, alt: "Wedding Sample 98	" },
    { class: "sizeOne", img: w99, alt: "Wedding Sample 99	" },
    { class: "sizeOne", img: w100, alt: "Wedding Sample 100" },
    { class: "sizeOne", img: w101, alt: "Wedding Sample 101" },
    { class: "sizeOne", img: w102, alt: "Wedding Sample 102" },
    { class: "sizeOne", img: w103, alt: "Wedding Sample 103" },
    { class: "sizeOne", img: w104, alt: "Wedding Sample 104" },
    { class: "sizeOne", img: w105, alt: "Wedding Sample 105" },
    { class: "sizeOne", img: w106, alt: "Wedding Sample 106" },
    { class: "sizeOne", img: w107, alt: "Wedding Sample 107" },
    { class: "sizeOne", img: w108, alt: "Wedding Sample 108" },
    { class: "sizeOne", img: w109, alt: "Wedding Sample 109" },
    { class: "sizeOne", img: w110, alt: "Wedding Sample 110" },
    { class: "sizeOne", img: w111, alt: "Wedding Sample 111" },
    { class: "sizeOne", img: w112, alt: "Wedding Sample 112" },
    { class: "sizeOne", img: w113, alt: "Wedding Sample 113" },
    { class: "sizeOne", img: w114, alt: "Wedding Sample 114" },
    { class: "sizeOne", img: w115, alt: "Wedding Sample 115" },
  ];
  const weddingSizeTwoArray = [
    { class: "sizeTwo", img: W1, alt: "Wedding 4x6 Sample 1" },
    { class: "sizeTwo", img: W2, alt: "Wedding 4x6 Sample 2" },
    { class: "sizeTwo", img: W3, alt: "Wedding 4x6 Sample 3" },
    { class: "sizeTwo", img: W4, alt: "Wedding 4x6 Sample 4" },
    { class: "sizeTwo", img: W5, alt: "Wedding 4x6 Sample 5" },
    { class: "sizeTwo", img: W6, alt: "Wedding 4x6 Sample 6" },
    { class: "sizeTwo", img: W7, alt: "Wedding 4x6 Sample 7" },
    { class: "sizeTwo", img: W8, alt: "Wedding 4x6 Sample 8" },
    { class: "sizeTwo", img: W9, alt: "Wedding 4x6 Sample 9" },
    { class: "sizeTwo", img: W10, alt: "Wedding 4x6 Sample 10" },
    { class: "sizeTwo", img: W11, alt: "Wedding 4x6 Sample 11" },
    { class: "sizeTwo", img: W12, alt: "Wedding 4x6 Sample 12" },
    { class: "sizeTwo", img: W13, alt: "Wedding 4x6 Sample 13" },
    { class: "sizeTwo", img: W14, alt: "Wedding 4x6 Sample 14" },
    { class: "sizeTwo", img: W15, alt: "Wedding 4x6 Sample 15" },
  ];
  const birthdaySizeOneArray = [
    { class: "sizeOne", img: b1, alt: "Birthday Sample	1	" },
    { class: "sizeOne", img: b2, alt: "Birthday Sample	2	" },
    { class: "sizeOne", img: b3, alt: "Birthday Sample	3	" },
    { class: "sizeOne", img: b4, alt: "Birthday Sample	4	" },
    { class: "sizeOne", img: b5, alt: "Birthday Sample	5	" },
    { class: "sizeOne", img: b6, alt: "Birthday Sample	6	" },
    { class: "sizeOne", img: b7, alt: "Birthday Sample	7	" },
    { class: "sizeOne", img: b8, alt: "Birthday Sample	8	" },
    { class: "sizeOne", img: b9, alt: "Birthday Sample	9	" },
    { class: "sizeOne", img: b10, alt: "Birthday Sample	10	" },
    { class: "sizeOne", img: b11, alt: "Birthday Sample	11	" },
    { class: "sizeOne", img: b12, alt: "Birthday Sample	12	" },
    { class: "sizeOne", img: b13, alt: "Birthday Sample	13	" },
    { class: "sizeOne", img: b14, alt: "Birthday Sample	14	" },
    { class: "sizeOne", img: b15, alt: "Birthday Sample	15	" },
    { class: "sizeOne", img: b16, alt: "Birthday Sample	16	" },
    { class: "sizeOne", img: b17, alt: "Birthday Sample	17	" },
    { class: "sizeOne", img: b18, alt: "Birthday Sample	18	" },
    { class: "sizeOne", img: b19, alt: "Birthday Sample	19	" },
    { class: "sizeOne", img: b20, alt: "Birthday Sample	20	" },
    { class: "sizeOne", img: b21, alt: "Birthday Sample	21	" },
    { class: "sizeOne", img: b22, alt: "Birthday Sample	22	" },
    { class: "sizeOne", img: b23, alt: "Birthday Sample	23	" },
    { class: "sizeOne", img: b24, alt: "Birthday Sample	24	" },
    { class: "sizeOne", img: b25, alt: "Birthday Sample	25	" },
    { class: "sizeOne", img: b26, alt: "Birthday Sample	26	" },
    { class: "sizeOne", img: b27, alt: "Birthday Sample	27	" },
    { class: "sizeOne", img: b28, alt: "Birthday Sample	28	" },
  ];
  const birthdaySizeTwoArray = [
    { class: "sizeTwo", img: B1, alt: "Birthday 4x6 Sample 	1	" },
    { class: "sizeTwo", img: B2, alt: "Birthday 4x6 Sample 	2	" },
    { class: "sizeTwo", img: B3, alt: "Birthday 4x6 Sample 	3	" },
    { class: "sizeTwo", img: B4, alt: "Birthday 4x6 Sample 	4	" },
    { class: "sizeTwo", img: B5, alt: "Birthday 4x6 Sample 	5	" },
    { class: "sizeTwo", img: B6, alt: "Birthday 4x6 Sample 	6	" },
    { class: "sizeTwo", img: B7, alt: "Birthday 4x6 Sample 	7	" },
    { class: "sizeTwo", img: B8, alt: "Birthday 4x6 Sample 	8	" },
  ];
  const corporateSizeOneArray = [
    { class: "sizeOne", img: c1, alt: "Corporate Sample	1	" },
    { class: "sizeOne", img: c2, alt: "Corporate Sample	2	" },
    { class: "sizeOne", img: c3, alt: "Corporate Sample	3	" },
    { class: "sizeOne", img: c4, alt: "Corporate Sample	4	" },
    { class: "sizeOne", img: c5, alt: "Corporate Sample	5	" },
    { class: "sizeOne", img: c6, alt: "Corporate Sample	6	" },
    { class: "sizeOne", img: c7, alt: "Corporate Sample	7	" },
    { class: "sizeOne", img: c8, alt: "Corporate Sample	8	" },
    { class: "sizeOne", img: c9, alt: "Corporate Sample	9	" },
    { class: "sizeOne", img: c10, alt: "Corporate Sample	10	" },
    { class: "sizeOne", img: c11, alt: "Corporate Sample	11	" },
    { class: "sizeOne", img: c12, alt: "Corporate Sample	12	" },
    { class: "sizeOne", img: c13, alt: "Corporate Sample	13	" },
    { class: "sizeOne", img: c14, alt: "Corporate Sample	14	" },
    { class: "sizeOne", img: c15, alt: "Corporate Sample	15	" },
    { class: "sizeOne", img: c16, alt: "Corporate Sample	16	" },
    { class: "sizeOne", img: c17, alt: "Corporate Sample	17	" },
    { class: "sizeOne", img: c18, alt: "Corporate Sample	18	" },
    { class: "sizeOne", img: c19, alt: "Corporate Sample	19	" },
    { class: "sizeOne", img: c20, alt: "Corporate Sample	20	" },
    { class: "sizeOne", img: c21, alt: "Corporate Sample	21	" },
    { class: "sizeOne", img: c22, alt: "Corporate Sample	22	" },
    { class: "sizeOne", img: c23, alt: "Corporate Sample	23	" },
    { class: "sizeOne", img: c24, alt: "Corporate Sample	24	" },
    { class: "sizeOne", img: c25, alt: "Corporate Sample	25	" },
    { class: "sizeOne", img: c26, alt: "Corporate Sample	26	" },
    { class: "sizeOne", img: c27, alt: "Corporate Sample	27	" },
    { class: "sizeOne", img: c28, alt: "Corporate Sample	28	" },
    { class: "sizeOne", img: c29, alt: "Corporate Sample	29	" },
    { class: "sizeOne", img: c30, alt: "Corporate Sample	30	" },
    { class: "sizeOne", img: c31, alt: "Corporate Sample	31	" },
    { class: "sizeOne", img: c32, alt: "Corporate Sample	32	" },
    { class: "sizeOne", img: c33, alt: "Corporate Sample	33	" },
    { class: "sizeOne", img: c34, alt: "Corporate Sample	34	" },
    { class: "sizeOne", img: c35, alt: "Corporate Sample	35	" },
    { class: "sizeOne", img: c36, alt: "Corporate Sample	36	" },
    { class: "sizeOne", img: c37, alt: "Corporate Sample	37	" },
    { class: "sizeOne", img: c38, alt: "Corporate Sample	38	" },
    { class: "sizeOne", img: c39, alt: "Corporate Sample	39	" },
    { class: "sizeOne", img: c40, alt: "Corporate Sample	40	" },
    { class: "sizeOne", img: c41, alt: "Corporate Sample	41	" },
    { class: "sizeOne", img: c42, alt: "Corporate Sample	42	" },
    { class: "sizeOne", img: c43, alt: "Corporate Sample	43	" },
    { class: "sizeOne", img: c44, alt: "Corporate Sample	44	" },
    { class: "sizeOne", img: c45, alt: "Corporate Sample	45	" },
    { class: "sizeOne", img: c46, alt: "Corporate Sample	46	" },
    { class: "sizeOne", img: c47, alt: "Corporate Sample	47	" },
    { class: "sizeOne", img: c48, alt: "Corporate Sample	48	" },
    { class: "sizeOne", img: c49, alt: "Corporate Sample	49	" },
    { class: "sizeOne", img: c50, alt: "Corporate Sample	50	" },
    { class: "sizeOne", img: c51, alt: "Corporate Sample	51	" },
    { class: "sizeOne", img: c52, alt: "Corporate Sample	52	" },
    { class: "sizeOne", img: c53, alt: "Corporate Sample	53	" },
    { class: "sizeOne", img: c54, alt: "Corporate Sample	54	" },
    { class: "sizeOne", img: c55, alt: "Corporate Sample	55	" },
    { class: "sizeOne", img: c56, alt: "Corporate Sample	56	" },
    { class: "sizeOne", img: c57, alt: "Corporate Sample	57	" },
    { class: "sizeOne", img: c58, alt: "Corporate Sample	58	" },
    { class: "sizeOne", img: c59, alt: "Corporate Sample	59	" },
    { class: "sizeOne", img: c60, alt: "Corporate Sample	60	" },
    { class: "sizeOne", img: c61, alt: "Corporate Sample	61	" },
    { class: "sizeOne", img: c62, alt: "Corporate Sample	62	" },
    { class: "sizeOne", img: c63, alt: "Corporate Sample	63	" },
    { class: "sizeOne", img: c64, alt: "Corporate Sample	64	" },
    { class: "sizeOne", img: c65, alt: "Corporate Sample	65	" },
    { class: "sizeOne", img: c66, alt: "Corporate Sample	66	" },
    { class: "sizeOne", img: c67, alt: "Corporate Sample	67	" },
    { class: "sizeOne", img: c68, alt: "Corporate Sample	68	" },
    { class: "sizeOne", img: c69, alt: "Corporate Sample	69	" },
    { class: "sizeOne", img: c70, alt: "Corporate Sample	70	" },
    { class: "sizeOne", img: c71, alt: "Corporate Sample	71	" },
    { class: "sizeOne", img: c72, alt: "Corporate Sample	72	" },
    { class: "sizeOne", img: c73, alt: "Corporate Sample	73	" },
    { class: "sizeOne", img: c74, alt: "Corporate Sample	74	" },
    { class: "sizeOne", img: c75, alt: "Corporate Sample	75	" },
  ];
  const corporateSizeTwoArray = [
    { class: "sizeOneH", img: c76, alt: "Corporate Sample	76	" },
    { class: "sizeOneH", img: c77, alt: "Corporate Sample	77	" },
    { class: "sizeOneH", img: c78, alt: "Corporate Sample	78	" },
    { class: "sizeOneH", img: c79, alt: "Corporate Sample	79	" },
    { class: "sizeTwo", img: C1, alt: "Corporate 4x6 Sample 	1	" },
    { class: "sizeTwo", img: C2, alt: "Corporate 4x6 Sample 	2	" },
    { class: "sizeTwo", img: C3, alt: "Corporate 4x6 Sample 	3	" },
    { class: "sizeTwo", img: C4, alt: "Corporate 4x6 Sample 	4	" },
    { class: "sizeTwo", img: C5, alt: "Corporate 4x6 Sample 	5	" },
    { class: "sizeTwo", img: C6, alt: "Corporate 4x6 Sample 	6	" },
    { class: "sizeTwo", img: C7, alt: "Corporate 4x6 Sample 	7	" },
    { class: "sizeTwo", img: C8, alt: "Corporate 4x6 Sample 	8	" },
    { class: "sizeTwo", img: C9, alt: "Corporate 4x6 Sample 	9	" },
    { class: "sizeTwo", img: C10, alt: "Corporate 4x6 Sample 	10	" },
    { class: "sizeTwo", img: C11, alt: "Corporate 4x6 Sample 	11	" },
    { class: "sizeTwo", img: C12, alt: "Corporate 4x6 Sample 	12	" },
    { class: "sizeTwo", img: C13, alt: "Corporate 4x6 Sample 	13	" },
    { class: "sizeTwo", img: C14, alt: "Corporate 4x6 Sample 	14	" },
    { class: "sizeTwo", img: C15, alt: "Corporate 4x6 Sample 	15	" },
    { class: "sizeTwo", img: C16, alt: "Corporate 4x6 Sample 	16	" },
    { class: "sizeTwo", img: C17, alt: "Corporate 4x6 Sample 	17	" },
    { class: "sizeTwo", img: C18, alt: "Corporate 4x6 Sample 	18	" },
    { class: "sizeTwo", img: C19, alt: "Corporate 4x6 Sample 	19	" },
    { class: "sizeTwo", img: C20, alt: "Corporate 4x6 Sample 	20	" },
    { class: "sizeTwo", img: C21, alt: "Corporate 4x6 Sample 	21	" },
  ];
  const holidaySizeOneArray = [
    { class: "sizeOne", img: h1, alt: "Holiday Sample	1	" },
    { class: "sizeOne", img: h2, alt: "Holiday Sample	2	" },
    { class: "sizeOne", img: h3, alt: "Holiday Sample	3	" },
    { class: "sizeOne", img: h4, alt: "Holiday Sample	4	" },
    { class: "sizeOne", img: h5, alt: "Holiday Sample	5	" },
    { class: "sizeOne", img: h6, alt: "Holiday Sample	6	" },
    { class: "sizeOne", img: h7, alt: "Holiday Sample	7	" },
    { class: "sizeOne", img: h8, alt: "Holiday Sample	8	" },
    { class: "sizeOne", img: h9, alt: "Holiday Sample	9	" },
    { class: "sizeOne", img: h10, alt: "Holiday Sample	10	" },
    { class: "sizeOne", img: h11, alt: "Holiday Sample	11	" },
    { class: "sizeOne", img: h12, alt: "Holiday Sample	12	" },
    { class: "sizeOne", img: h13, alt: "Holiday Sample	13	" },
    { class: "sizeOne", img: h14, alt: "Holiday Sample	14	" },
    { class: "sizeOne", img: h15, alt: "Holiday Sample	15	" },
    { class: "sizeOne", img: h16, alt: "Holiday Sample	16	" },
    { class: "sizeOne", img: h17, alt: "Holiday Sample	17	" },
    { class: "sizeOne", img: h18, alt: "Holiday Sample	18	" },
    { class: "sizeOne", img: h19, alt: "Holiday Sample	19	" },
    { class: "sizeOne", img: h20, alt: "Holiday Sample	20	" },
    { class: "sizeOne", img: h21, alt: "Holiday Sample	21	" },
    { class: "sizeOne", img: h22, alt: "Holiday Sample	22	" },
    { class: "sizeOne", img: h23, alt: "Holiday Sample	23	" },
    { class: "sizeOne", img: h24, alt: "Holiday Sample	24	" },
    { class: "sizeOne", img: h25, alt: "Holiday Sample	25	" },
    { class: "sizeOne", img: h26, alt: "Holiday Sample	26	" },
    { class: "sizeOne", img: h27, alt: "Holiday Sample	27	" },
    { class: "sizeOne", img: h28, alt: "Holiday Sample	28	" },
    { class: "sizeOne", img: h29, alt: "Holiday Sample	29	" },
    { class: "sizeOne", img: h30, alt: "Holiday Sample	30	" },
    { class: "sizeOne", img: h31, alt: "Holiday Sample	31	" },
    { class: "sizeOne", img: h32, alt: "Holiday Sample	32	" },
    { class: "sizeOne", img: h33, alt: "Holiday Sample	33	" },
    { class: "sizeOne", img: h34, alt: "Holiday Sample	34	" },
    { class: "sizeOne", img: h35, alt: "Holiday Sample	35	" },
    { class: "sizeOne", img: h36, alt: "Holiday Sample	36	" },
    { class: "sizeOne", img: h37, alt: "Holiday Sample	37	" },
    { class: "sizeOne", img: h38, alt: "Holiday Sample	38	" },
    { class: "sizeOne", img: h39, alt: "Holiday Sample	39	" },
    { class: "sizeOne", img: h40, alt: "Holiday Sample	40	" },
    { class: "sizeOne", img: h41, alt: "Holiday Sample	41	" },
    { class: "sizeOne", img: h42, alt: "Holiday Sample	42	" },
    { class: "sizeOne", img: h43, alt: "Holiday Sample	43	" },
    { class: "sizeOne", img: h44, alt: "Holiday Sample	44	" },
    { class: "sizeOne", img: h45, alt: "Holiday Sample	45	" },
    { class: "sizeOne", img: h46, alt: "Holiday Sample	46	" },
    { class: "sizeOne", img: h47, alt: "Holiday Sample	47	" },
    { class: "sizeOne", img: h48, alt: "Holiday Sample	48	" },
    { class: "sizeOne", img: h49, alt: "Holiday Sample	49	" },
    { class: "sizeOne", img: h50, alt: "Holiday Sample	50	" },
  ];
  const holidaySizeTwoArray = [
    { class: "sizeTwo", img: H1, alt: "Holiday 4x6 Sample 	1	" },
    { class: "sizeTwo", img: H2, alt: "Holiday 4x6 Sample 	2	" },
    { class: "sizeTwo", img: H3, alt: "Holiday 4x6 Sample 	3	" },
    { class: "sizeTwo", img: H4, alt: "Holiday 4x6 Sample 	4	" },
    { class: "sizeTwo", img: H5, alt: "Holiday 4x6 Sample 	5	" },
  ];
  const schoolSizeOneArray = [
    { class: "sizeOne", img: s1, alt: "School Sample	1	" },
    { class: "sizeOne", img: s2, alt: "School Sample	2	" },
    { class: "sizeOne", img: s3, alt: "School Sample	3	" },
    { class: "sizeOne", img: s4, alt: "School Sample	4	" },
    { class: "sizeOne", img: s5, alt: "School Sample	5	" },
    { class: "sizeOne", img: s6, alt: "School Sample	6	" },
    { class: "sizeOne", img: s7, alt: "School Sample	7	" },
    { class: "sizeOne", img: s8, alt: "School Sample	8	" },
    { class: "sizeOne", img: s9, alt: "School Sample	9	" },
    { class: "sizeOne", img: s10, alt: "School Sample	10	" },
    { class: "sizeOne", img: s11, alt: "School Sample	11	" },
    { class: "sizeOne", img: s12, alt: "School Sample	12	" },
    { class: "sizeOne", img: s13, alt: "School Sample	13	" },
    { class: "sizeOne", img: s14, alt: "School Sample	14	" },
    { class: "sizeOne", img: s15, alt: "School Sample	15	" },
    { class: "sizeOne", img: s16, alt: "School Sample	16	" },
    { class: "sizeOne", img: s17, alt: "School Sample	17	" },
    { class: "sizeOne", img: s18, alt: "School Sample	18	" },
    { class: "sizeOne", img: s19, alt: "School Sample	19	" },
    { class: "sizeOne", img: s20, alt: "School Sample	20	" },
    { class: "sizeOne", img: s21, alt: "School Sample	21	" },
    { class: "sizeOne", img: s22, alt: "School Sample	22	" },
    { class: "sizeOne", img: s23, alt: "School Sample	23	" },
    { class: "sizeOne", img: s24, alt: "School Sample	24	" },
    { class: "sizeOne", img: s25, alt: "School Sample	25	" },
    { class: "sizeOne", img: s26, alt: "School Sample	26	" },
    { class: "sizeOne", img: s27, alt: "School Sample	27	" },
    { class: "sizeOne", img: s28, alt: "School Sample	28	" },
    { class: "sizeOne", img: s29, alt: "School Sample	29	" },
    { class: "sizeOne", img: s30, alt: "School Sample	30	" },
    { class: "sizeOne", img: s31, alt: "School Sample	31	" },
    { class: "sizeOne", img: s32, alt: "School Sample	32	" },
    { class: "sizeOne", img: s33, alt: "School Sample	33	" },
    { class: "sizeOne", img: s34, alt: "School Sample	34	" },
    { class: "sizeOne", img: s35, alt: "School Sample	35	" },
    { class: "sizeOne", img: s36, alt: "School Sample	36	" },
    { class: "sizeOne", img: s37, alt: "School Sample	37	" },
    { class: "sizeOne", img: s38, alt: "School Sample	38	" },
    { class: "sizeOne", img: s39, alt: "School Sample	39	" },
    { class: "sizeOne", img: s40, alt: "School Sample	40	" },
    { class: "sizeOne", img: s41, alt: "School Sample	41	" },
    { class: "sizeOne", img: s42, alt: "School Sample	42	" },
    { class: "sizeOne", img: s43, alt: "School Sample	43	" },
    { class: "sizeOne", img: s44, alt: "School Sample	44	" },
    { class: "sizeOne", img: s45, alt: "School Sample	45	" },
    { class: "sizeOne", img: s46, alt: "School Sample	46	" },
    { class: "sizeOne", img: s47, alt: "School Sample	47	" },
    { class: "sizeOne", img: s48, alt: "School Sample	48	" },
    { class: "sizeOne", img: s49, alt: "School Sample	49	" },
    { class: "sizeOne", img: s50, alt: "School Sample	50	" },
  ];
  const otherSizeOneArray = [
    { class: "sizeOne", img: o1, alt: "Various Sample	1	" },
    { class: "sizeOne", img: o2, alt: "Various Sample	2	" },
    { class: "sizeOne", img: o3, alt: "Various Sample	3	" },
    { class: "sizeOne", img: o4, alt: "Various Sample	4	" },
    { class: "sizeOne", img: o5, alt: "Various Sample	5	" },
    { class: "sizeOne", img: o6, alt: "Various Sample	6	" },
    { class: "sizeOne", img: o7, alt: "Various Sample	7	" },
    { class: "sizeOne", img: o8, alt: "Various Sample	8	" },
    { class: "sizeOne", img: o9, alt: "Various Sample	9	" },
    { class: "sizeOne", img: o10, alt: "Various Sample	10	" },
    { class: "sizeOne", img: o11, alt: "Various Sample	11	" },
    { class: "sizeOne", img: o12, alt: "Various Sample	12	" },
    { class: "sizeOne", img: o13, alt: "Various Sample	13	" },
    { class: "sizeOne", img: o14, alt: "Various Sample	14	" },
    { class: "sizeOne", img: o15, alt: "Various Sample	15	" },
    { class: "sizeOne", img: o16, alt: "Various Sample	16	" },
    { class: "sizeOne", img: o17, alt: "Various Sample	17	" },
    { class: "sizeOne", img: o18, alt: "Various Sample	18	" },
    { class: "sizeOne", img: o19, alt: "Various Sample	19	" },
    { class: "sizeOne", img: o20, alt: "Various Sample	20	" },
    { class: "sizeOne", img: o21, alt: "Various Sample	21	" },
    { class: "sizeOne", img: o22, alt: "Various Sample	22	" },
    { class: "sizeOne", img: o23, alt: "Various Sample	23	" },
    { class: "sizeOne", img: o24, alt: "Various Sample	24	" },
    { class: "sizeOne", img: o25, alt: "Various Sample	25	" },
    { class: "sizeOne", img: o26, alt: "Various Sample	26	" },
    { class: "sizeOne", img: o27, alt: "Various Sample	27	" },
    { class: "sizeOne", img: o28, alt: "Various Sample	28	" },
    { class: "sizeOne", img: o29, alt: "Various Sample	29	" },
    { class: "sizeOne", img: o30, alt: "Various Sample	30	" },
    { class: "sizeOne", img: o31, alt: "Various Sample	31	" },
    { class: "sizeOne", img: o32, alt: "Various Sample	32	" },
    { class: "sizeOne", img: o33, alt: "Various Sample	33	" },
    { class: "sizeOne", img: o34, alt: "Various Sample	34	" },
    { class: "sizeOne", img: o35, alt: "Various Sample	35	" },
    { class: "sizeOne", img: o36, alt: "Various Sample	36	" },
    { class: "sizeOne", img: o37, alt: "Various Sample	37	" },
    { class: "sizeOne", img: o38, alt: "Various Sample	38	" },
    { class: "sizeOne", img: o39, alt: "Various Sample	39	" },
    { class: "sizeOne", img: o40, alt: "Various Sample	40	" },
    { class: "sizeOne", img: o41, alt: "Various Sample	41	" },
    { class: "sizeOne", img: o42, alt: "Various Sample	42	" },
    { class: "sizeOne", img: o43, alt: "Various Sample	43	" },
    { class: "sizeOne", img: o44, alt: "Various Sample	44	" },
    { class: "sizeOne", img: o45, alt: "Various Sample	45	" },
  ];
  const otherSizeTwoArray = [
    { class: "sizeTwo", img: O1, alt: "Various 4x6 Sample 	1	" },
    { class: "sizeTwo", img: O2, alt: "Various 4x6 Sample 	2	" },
    { class: "sizeTwo", img: O3, alt: "Various 4x6 Sample 	3	" },
    { class: "sizeTwo", img: O4, alt: "Various 4x6 Sample 	4	" },
    { class: "sizeTwo", img: O5, alt: "Various 4x6 Sample 	5	" },
    { class: "sizeTwo", img: O6, alt: "Various 4x6 Sample 	6	" },
  ];
  return (
    <>
      <div className="wrapper layoutContainer">
        <h1 className="orangeBlackTitle">
          Personalizing your{" "}
          <span className="highlightOrange">Photo Booth</span> rental with a
          custom template
        </h1>
        <section className="layoutTextContainer">
          <div className="layoutLaptop">
            <img src={layout} alt="Sample photo strip layouts and designs" />
          </div>
          <div className="layoutText">
            <h3>
              Our professional graphic designers will custom design a personal
              template just for you.
            </h3>
            <p>
              Most of our clients show us their wedding theme and colour's and
              our graphic designers will creatively design a layout that will
              look consistent with your theme. You can certainly send us one of
              your invitations if it contains a particular design you like!
            </p>
            <p>
              From classy and elegant to retro and vintage, and anything stylish
              in between, your template will be designed to provide you with a
              unique and personal photo booth rental experience.
            </p>
            <h3>A variety of photobooth templates to choose from </h3>
            <p>
              To get the process started, simply choose from one of the
              templates below.
            </p>
          </div>
        </section>
        <div className="layoutNav">
          <a href="#wedding" className="highlightOrange">
            Weddings
          </a>
          <span> | </span>
          <a href="#birthday" className="highlightOrange">
            Birthday Parties
          </a>
          <span> | </span>
          <a href="#corporate" className="highlightOrange">
            Corporate Events
          </a>
          <span> | </span>
          <a href="#holiday" className="highlightOrange">
            Holiday Parties
          </a>
          <span> | </span>
          <a href="#school" className="highlightOrange">
            School Events
          </a>
          <span> | </span>
          <a href="#other" className="highlightOrange">
            Other Layouts
          </a>
        </div>
        <section id="wedding">
          <LayoutTemplateHero
            arrayOne={weddingSizeOneArray}
            arrayTwo={weddingSizeTwoArray}
            title="Wedding"
          />
        </section>
        <section id="birthday">
          <LayoutTemplateHero
            arrayOne={birthdaySizeOneArray}
            arrayTwo={birthdaySizeTwoArray}
            title="Birthday Party"
          />
        </section>
        <section id="corporate">
          <LayoutTemplateHero
            arrayOne={corporateSizeOneArray}
            arrayTwo={corporateSizeTwoArray}
            title="Corporate Event"
          />
        </section>
        <section id="holiday">
          <LayoutTemplateHero
            arrayOne={holidaySizeOneArray}
            arrayTwo={holidaySizeTwoArray}
            title="Holiday Event"
          />
        </section>
        <section id="school">
          <LayoutTemplateHero
            arrayOne={schoolSizeOneArray}
            title="School Event"
          />
        </section>
        <section id="other">
          <LayoutTemplateHero
            arrayOne={otherSizeOneArray}
            arrayTwo={otherSizeTwoArray}
            title="Various Event"
          />
        </section>
      </div>
      <Booking
        title1="We can spice up your next event with our"
        highlight="Photo Booth"
        bookingP="Feel free to share your thoughts and ideas and we would be more than happy to custom design a template for your next event."
        link="/contactUs"
        buttonText="Contact Us Today!"
      />
    </>
  );
}
