import React from "react";
import mapIcon from "../../media/map.png";
import mailIcon from "../../media/email.png";
import phoneIcon from "../../media/phone.png";

export default function ContactInfo() {
  return (
    <address>
      <div className="address">
        <img src={mapIcon} alt="Map Icon" aria-hidden="true" />
        <p>Take My Photo | Photo Booth Toronto, ON M9B2C2</p>
      </div>
      <div className="email">
        <img src={mailIcon} alt="Mail Icon" aria-hidden="true" />
        <a href="mailto:smile@takemyphoto.ca">smile@takemyphoto.ca</a>
      </div>
      <div className="phone">
        <img src={phoneIcon} alt="Phone Icon" aria-hidden="true" />
        <a href="tel:+6474711513">647.471.1513</a>
      </div>
    </address>
  );
}
