export default function LayoutTemplateHero({ arrayOne, arrayTwo, title }) {
  return (
    <>
      <h2 className="orangeBlackTitle layoutTitle">
        {title} <span className="highlightOrange">Layouts</span>
      </h2>
      <div className="sampleContainer">
        {" "}
        {arrayOne.map((sample, i) => {
          return (
            <div key={i} className="sampleIndiv">
              <div className={sample.class}>
                <img src={sample.img} alt={sample.alt} />
              </div>
              <p>{sample.alt}</p>
            </div>
          );
        })}
      </div>
      <div className="sampleContainer">
        {arrayTwo &&
          arrayTwo.map((sample, i) => {
            return (
              <div key={i} className="sampleIndiv">
                <div className={sample.class}>
                  <img src={sample.img} alt={sample.alt} />
                </div>
                <p>{sample.alt}</p>
              </div>
            );
          })}
      </div>
    </>
  );
}
