import React from "react";
import ContactInfo from "./reusableComponents/ContactInfo";

export default function Privacy() {
  return (
    <div className="privacyWindow wrapper">
      <div className="privacyTitle">
        <h2 className="orangeBlackTitle">
          Privacy <span className="highlightOrange">Policy</span>
        </h2>
      </div>

      <div className="privacyContent wrapper">
        <article>
          <h4>What information do we collect?</h4>
          <p>
            Take My Photo collects information from you when you subscribe to
            our newsletter, request a quote, fill out a form, survey, submit
            feedback, or book a photo booth.
          </p>
          <p>
            When ordering or registering on our site, as appropriate, you may be
            asked to enter your name or e-mail address. You may, however, visit
            our site anonymously.
          </p>
        </article>

        <article>
          <h4>What do we use your information for?</h4>
          <p>
            Any of the information we collect from you may be used in one of the
            following ways:
          </p>
          <ul className="policyList">
            <li>
              <p>
                To personalize your experience your information helps us to
                better respond to your individual needs
              </p>
            </li>
            <li>
              <p>
                To improve our website (we continually strive to improve our
                website offerings based on the information and feedback we
                receive from you)
              </p>
            </li>
            <li>
              <p>
                To improve customer service (your information helps us to more
                effectively respond to your customer service requests and
                support needs)
              </p>
            </li>
            <li>
              <p>
                To administer a contest, promotion, survey, or other site
                feature
              </p>
            </li>
            <li>
              <p>
                To send periodic emails. The email address you provide for order
                processing, may be used to send you information and updates
                pertaining to your order, in addition to receiving occasional
                company news, updates, related product or service information,
                etc.
              </p>
            </li>
          </ul>
          <p>
            Note: If at any time you would like to unsubscribe from receiving
            future emails, we include detailed unsubscribe instructions at the
            bottom of each email.
          </p>
        </article>

        <article>
          <h4>Do we disclose any information to outside parties?</h4>
          <p>
            No. Take My Photo does not sell, trade, or otherwise transfer to
            outside parties your personally identifiable information. This does
            not include trusted third parties who assist us in operating our
            website, conducting our business, or servicing you, so long as those
            parties agree to keep this information confidential. We may also
            release your information when we believe release is appropriate to
            comply with the law, enforce our site policies, or protect ours or
            others rights, property, or safety. However, non-personally
            identifiable visitor information may be provided to other parties
            for marketing, advertising, or other uses.
          </p>
        </article>

        <article>
          <h4>Third party links</h4>
          <p>
            Occasionally, at our discretion, we may include or offer third party
            products or services on our website. These third party sites have
            separate and independent privacy policies. We therefore have no
            responsibility or liability for the content and activities of these
            linked sites. Nonetheless, we seek to protect the integrity of our
            site and welcome any feedback about these sites.
          </p>
        </article>

        <article>
          <h4>Online Privacy Policy Only</h4>
          <p>
            This online privacy policy applies only to information collected
            through our website and not to information collected offline.
          </p>
        </article>

        <article>
          <h4>Your Consent</h4>
          <p>By using our site, you consent to our privacy policy.</p>
        </article>

        <article>
          <h4>Changes to our Privacy Policy</h4>
          <p>
            If we decide to change our privacy policy, we will post those
            changes on this page, and/or update the Privacy Policy modification
            date below.
          </p>

          <p className="policyModified">
            This policy was last modified on October 3, 2018
          </p>
        </article>

        <article>
          <h4>Contacting Us</h4>
          <p>
            If there are any questions regarding this privacy policy you may
            contact us using the information below.
          </p>
          <ContactInfo />
        </article>
      </div>

    </div>
  );
}
