import React from "react";
import Nav from "./Nav";

export default function Header() {
  return (
    <header>
      <div className="header">
        <div className="wrapper header">
          <p role="heading" aria-level="5" className="canadian">
            Proudly Canadian
          </p>
          <div className="telContainer">
            <p role="heading" aria-level="5">
              Call Us Today:
            </p>
            <a className="tel" href="tel:+6474711513">
              647.471.1513
            </a>
          </div>
        </div>
      </div>
      <Nav />
    </header>
  );
}
