import { Link } from "react-router-dom";
import facebookIcon from "../../media/facebook.png";
import instagramIcon from "../../media/instagram.png";
export default function SocialMedia() {
  return (
    <>
      <ul className="socialContainer">
        <li>
          <Link
            to="https://www.facebook.com/takemyphoto.ca"
            target="_blank"
            aria-label="Will direct you to Facebook page"
          >
            <img
              src={facebookIcon}
              alt="Click here to be directed to Take My Photo's Facebook page"
            />
          </Link>
        </li>
        <li>
          <Link
            to="https://www.instagram.com/takemyphoto.ca/"
            target="_blank"
            aria-label="Will direct you to Instagram page"
          >
            <img
              src={instagramIcon}
              alt="Click here to be directed to Take My Photo's Instagram page"
            />
          </Link>
        </li>
      </ul>
    </>
  );
}
