import React from "react";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function Footer() {
  const isMobile = useMediaQuery({ query: `(max-width: 800px)` });

  return (
    <footer className="footer">
      <div className="wrapper footerContainer">
        <nav className="footerNav">
          <ul>
            {!isMobile && (
              <>
                <li>
                  <NavLink to="" activeclassname="active">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/about" activeclassname="active">
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/prices" activeclassname="active">
                    Prices
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/reviews" activeclassname="active">
                    Reviews
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/faq" activeclassname="active">
                    FAQs
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact" activeclassname="active">
                    Contact Us
                  </NavLink>
                </li>
              </>
            )}
          </ul>
          <div className="copyrightContainer">
            <p>
              <a href="https://www.takemyphoto.ca">Take My Photo</a> © 2023
              <span>|</span>
              <NavLink to="/privacyPolicy">Privacy Policy</NavLink>
              <span>|</span>
              <NavLink to="/sitemap">Sitemap</NavLink>
            </p>
          </div>
        </nav>

        <div className="serviceLocations">
          <h4>Here is a list of some of the areas that we service:</h4>
          <p className="locationList">
            <span className="townList">Barrie,</span>
            <span className="townList">Orillia,</span>
            <span className="townList">Midland,</span>
            <span className="townList">Blue Mountain,</span>
            <span className="townList">Collingwood,</span>
            <span className="townList">Wasaga Beach,</span>
            <span className="townList">Alliston,</span>
            <span className="townList">Orangeville,</span>
            <span className="townList">Owen Sound,</span>
            <span className="townList">Bradford,</span>
            <span className="townList">Newmarket,</span>
            <span className="townList">Aurora,</span>
            <span className="townList">Keswick,</span>
            <span className="townList">Sutton,</span>
            <span className="townList">Beaverton,</span>
            <span className="townList">Muskoka,</span>
            <span className="townList">Huntsville,</span>
            <span className="townList">Bracebridge,</span>
            <span className="townList">Gravenhurst,</span>
            <span className="townList">Parry Sound,</span>
            <span className="townList">Haliburton,</span>
            <span className="townList">Richmond Hill,</span>
            <span className="townList">Thornhill,</span>
            <span className="townList">York Region,</span>
            <span className="townList">Simcoe County,</span>
            <span className="townList">Markham,</span>
            <span className="townList">Stouffville,</span>
            <span className="townList">Pickering,</span>
            <span className="townList">Whitby,</span>
            <span className="townList">Ajax,</span>
            <span className="townList">Oshawa,</span>
            <span className="townList">Toronto,</span>
            <span className="townList">GTA,</span>
            <span className="townList">King,</span>
            <span className="townList">Brampton,</span>
            <span className="townList">Scarborough,</span>
            <span className="townList">Vaughan,</span>
            <span className="townList">Mississauga,</span>
            <span className="townList">Burlington,</span>
            <span className="townList">Hamilton,</span>
            <span className="townList">Kitchener,</span>
            <span className="townList">Cambridge,</span>
            <span className="townList">Waterloo,</span>
            <span className="townList">Guelph,</span>
            <span className="townList">Oakville,</span>
            <span className="townList">Stoney Creek,</span>
            <span className="townList">Niagara Falls.</span>
          </p>
        </div>
      </div>
    </footer>
  );
}
