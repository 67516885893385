import ContactInfo from "./reusableComponents/ContactInfo";
import logo from "../media/Take-My-Photo-Logo.png";
export default function ThankYou() {
  return (
    <div className="thankContainer wrapper">
      <h1 className="orangeblackTitle thankYouTitle">
        Thank you for <span className="highlightOrange">contacting us! </span>
      </h1>
      <div className="thankContact">
        <ContactInfo />
        <div className="thankLogo">
          <img src={logo} alt="Take My Photo logo" />
        </div>
      </div>
    </div>
  );
}
