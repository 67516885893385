import React from "react";

import weCare from "../media/we-care-more-about-your-event.png";
import satisfactionBadge from "../media/satisfaction-guaranteed.png";
import guaranteedBadge from "../media/The-Best-Guaranteed.png";
import Booking from "./reusableComponents/Booking";
export default function Guarantee() {
  const policyArray = [
    {
      text: "We will strive at all times to exceed our clients’ expectations in the quality of our products, our service, and in our level of customer care.",
    },
    {
      text: "We will strive at all times to achieve our clients’ wishes and objectives, and commit to offer only the best advice, be transparent in our communication, and support and explain our position as necessary.",
    },
    {
      text: "We are always available to our clients on any day of the week and for any reason.",
    },
    {
      text: "We make every attempt to respond to voicemails, texts, and e-mail messages as soon and as quickly as possible.",
    },
    {
      text: "We aim to ensure that our clients fully understand what we may need from them in the way of support, information, material, or resources for their event.",
    },
    {
      text: "We will always respect our clients’ privacy before, during and after any event.",
    },
    {
      text: "We always seek and will act upon any client feedback that is provided to us.",
    },
  ];
  return (
    <>
      <div className="wrapper guaranteeContainer">
        <h1 className="orangeBlackTitle ">
          Our <span className="highlightOrange">Customer Satisfaction</span>{" "}
          Policy
        </h1>
        <div className="weCareImg weCareGuarantee">
          <img src={weCare} alt="We Care More About Your Event" />
        </div>
        <div className="guaranteeFlex">
          <div className="guaranteeBadges">
            <div>
              <img src={satisfactionBadge} alt="100% Satisfaction Guaranteed" />
            </div>
            <div>
              <img
                src={guaranteedBadge}
                alt="Guaranteed The Best Service Badge"
              />
            </div>
          </div>
          <div className="policyStatement">
            <h2>
              We are committed to providing the highest level of customer
              service and care to our clients. It is this commitment that makes
              us leaders in the photo booth industry.
            </h2>
            <ul className="policyArray sitemapLinks">
              {policyArray.map((policy, i) => {
                return (
                  <li key={i}>
                    <p>{policy.text}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <Booking
        title1="Do you have questions about our"
        highlight="Photo Booth"
        title2="Service"
        bookingP="Give us a call or send us an email any time. We are always more than happy to help."
        additional={
          <p>
            <a href="mailto:smile@takemyphoto.ca">smile@takemyphoto.ca</a> or{" "}
            <a href="tel:+6474711513">647.471.1513</a>
          </p>
        }
        buttonText="Contact Us Today!"
      />
    </>
  );
}
