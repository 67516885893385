import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./styles/sass/style.css";
import ScrollToTop from "./components/ScrollToTop";

import "./styles/sass/style.css";
import Header from "./components/navigation/Header";
import Footer from "./components/navigation/Footer";
import Home from "./components/Home";
import About from "./components/About";
import Layout from "./components/layout/Layout";
import Reviews from "./components/Reviews";
import Privacy from "./components/Privacy";
import Sitemap from "./components/Sitemap";
import Prices from "./components/Prices";
import FAQ from "./components/Faq";
import Contact from "./components/Contact";
import ThankYou from "./components/ThankYou";
import Guarantee from "./components/Guarantee";
import GoToTop from "./components/navigation/GoToTop";
function App() {
  return (
    <Router>
      <div className="app">
        <ScrollToTop>
          <a className="skip-main" href="#main">
            Skip to main content
          </a>
          <Header />
          <GoToTop />
          <main id="main">
            <Routes>
              <Route exact path="" element={<Home />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/reviews" element={<Reviews />} />
              <Route exact path="/prices" element={<Prices />} />
              <Route exact path="/faq" element={<FAQ />} />
              <Route exact path="/contactUs" element={<Contact />} />
              <Route exact path="/ourPromise" element={<Guarantee />} />
              <Route exact path="/sampleLayouts" element={<Layout />} />
              <Route exact path="/sitemap" element={<Sitemap />} />
              <Route exact path="/privacyPolicy" element={<Privacy />} />
              <Route exact path="/thankYou" element={<ThankYou />} />
            </Routes>
          </main>
          <Footer />
        </ScrollToTop>
      </div>
    </Router>
  );
}

export default App;
