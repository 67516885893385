import React from "react";
import { NavLink } from "react-router-dom";
import { FiCheck } from "react-icons/fi";

import Booking from "./reusableComponents/Booking";

// images
import badge from "../media/The-Best-Guaranteed.png";
import cards from "../media/Payment-Images.png";

export default function Sitemap() {
  return (
    <>
      <div className="pricingSection wrapper">
        <section className="pricing">
          <div className="pricingTitle">
            <h1>
              Photo Booth Rental <span className="highlight">Pricing</span>
            </h1>
          </div>

          <div className="pricingContent">
            <p>
              Our photo booth rental packages are{" "}
              <span className="highlight underline">All-Inclusive</span>.
              Everything you need to make your special event fun and
              entertaining is provided for a great price. Our photo booth is
              ultra-compact and versatile. It can be taken up stairs, fits in
              any elevator, and it can be used indoors or outdoors.
            </p>

            <p>
              A small deposit of $100 is required to hold and secure your date
              and time. The balance is due 7 days prior to your event date. We
              accept cheques, credit cards, PayPal transactions, and email money
              transfers. The deposit is non-refundable. Additional travel
              charges may apply if your event is outside of our free delivery
              area. Please contact us for a quote.
            </p>

            <div className="customPackages">
              <h2 className="customRentals">Custom Rental Packages</h2>
              <p>
                Take My Photo understands that every event is unique. It is our
                goal is to provide you with the best rental package that is the
                most cost effective. We provide complete custom designed print
                layouts, unique and themed props, and back drops. Contact us
                today to learn more about our custom rental packages for your
                next event.{" "}
              </p>
            </div>

            <div className="badgeImage">
              <img src={badge} alt="Guaranteed best service badge" />
            </div>
          </div>
        </section>

        <div className="pricesAsideContainer">
          <aside className="pricesContainer">
            <div className="packageTitle">
              <h2>
                All Packages <span className="highlightOrange">Include</span>
              </h2>
            </div>

            <ul>
              <li>
                <FiCheck aria-hidden="true" className="fiCheck" />
                <p>Unlimited use of the photo booth during the rental period</p>
              </li>
              <li>
                <FiCheck aria-hidden="true" className="fiCheck" />
                <p>
                  Instant digital photos printed onsite choice of colour or
                  black & white
                </p>
              </li>
              <li>
                <FiCheck aria-hidden="true" className="fiCheck" />
                <p>
                  Custom and personalized print design - can include text,
                  colours, company logo, images, in any design style
                </p>
              </li>
              <li>
                <FiCheck aria-hidden="true" className="fiCheck" />
                <p>
                  Silver sequin, gold sequin, light cream, or charcoal gray
                  backdrop
                </p>
              </li>
              <li>
                <FiCheck aria-hidden="true" className="fiCheck" />
                <p>
                  Delivery, set up, and take down of photo booth - outside of
                  rental time
                </p>
              </li>
              <li>
                <FiCheck aria-hidden="true" className="fiCheck" />
                <p>Event Specialists will be available onsite</p>
              </li>
              <li>
                <FiCheck aria-hidden="true" className="fiCheck" />
                <p>
                  A massive selection of props to enhance the fun and
                  entertainment
                </p>
              </li>
              <li>
                <FiCheck aria-hidden="true" className="fiCheck" />
                <p>
                  All High-Resolution digital photos taken are provided after
                  your event
                </p>
              </li>
            </ul>

            <div className="paymentMethod">
              <h4>Payment Options</h4>
              <img src={cards} alt="Guaranteed best service badge" />
            </div>
          </aside>
        </div>
      </div>
      <Booking
        title1="Book Your"
        highlight="Photo Booth"
        title2="Today!"
        bookingP="Contact us today for some great ideas on how we can make your next event more memorable with Take My Photo."
        link="/contactUs"
        buttonText="Contact Us Today!"
      />
    </>
  );
}
