import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import quote from "../media/Quote.png";
import arthritisImg from "../media/charities/Testimonial-Arthritis-Research-Foundation.jpg";
import georgeImg from "../media/charities/George-Brown-College-Testimonial.jpg";
import nottawasagaImg from "../media/charities/Nottawasaga-Futures-Award.jpg";

export default function Reviews() {
  const script = document.querySelector("script");

  const reviewsArray = [
    {
      text: "The staff were extremely professional and a pleasure to work with! We received many compliments on the photobooth set up.",
      name: "Sara Da Silva-Abraham,",
      place: "Project Sunshine",
    },
    {
      text: "We couldn't be more pleased with the quality and service of the photobooth at our show. Very professional and so much fun at the same time. We look forward to having them again at our show next year!",
      name: "Christine L.,",
      place: "Signature Group Inc.",
    },
    {
      text: "My husband and I loved Take My Photo's services! We had a fabulous experience working with them. Brian was very responsive and supportive via email prior to our wedding day. The photo booth looked great, it didn't take up a lot of room and was an overall big hit with all our guests. The photo quality is outstanding and we're looking forward to seeing the rest of our guests' photos on our DVD. Thanks again; great job!",
      name: "Kaya + Aaron, ",
      place: "Wedding",
    },
    {
      text: "Thanks for a great event! We will definitely be recommending to other brands in the company! ",
      name: "Sonia A., ",
      place: "Cara",
    },
    {
      text: "My new husband and I would like to express our thanks and gratitude. The photo booth was a success and everyone loved it! Your staff were so professional and kind! People really enjoyed the fact that each person received a copy of the photos and all the funky props! Thanks for being a part of our special day :)",
      name: "Dawn and Ray, ",
      place: "Wedding",
    },
    {
      text: "Was a pleasure to work with... They met all my needs in a fast and courteous manner. They arrived on time on time and everyone loved the set up! We had guests waiting in line because it looked like so much fun. What a great idea for any kind of event...young and old joined in the fun.",
      name: "Anne B., ",
      place: "Wedding",
    },
    {
      text: "The Take My Photo booth staff were all helpful and friendly. Everyone had a blast and were commending the photo booth initiative. Great prices too. Thanks for doing a great job! ",
      name: "Rizza F.,",
      place: "ABS-CBN Canada ULC",
    },
    {
      text: "Our guests had a blast with the photo booth, and we love the pictures we got out of it! We are so glad we did this - worth every penny. You were both a lot of fun to work with and I really appreciate how flexible you were in accommodating us. We couldn't be more happy with the way things worked out and we'll definitely recommend you to our friends!",
      name: "James S., ",
      place: "Wedding",
    },
    {
      text: "You were an amazing to have as part of The City's Empire. You were accommodating on time quick to set up and very engaging. Your photo design for our photo strips were a huge hit. We got many compliments about the photo booth.",
      name: "Nicole G., ",
      place: "George Brown College",
    },
    {
      text: "Very punctual and friendly service.",
      name: "Tania V., ",
      place: "Birthday Party",
    },
    {
      text: "Thank you so much for coming back to Lakehead University Orillia Campus! You were very helpful in making arrangements before the event and your flexibility with the custom photo strip was greatly appreciated. Thanks again and I'm sure we will have you back at Lakehead again!",
      name: "Brenda M., ",
      place: "Lakehead University Orillia Campus",
    },
    {
      text: "Brian was just amazing the photo booth was the life of the party I attended another party a week after my daughters And the kids were all still raving about Brian And his photo booth thank you so much for being A part of Cindy's party go bless!",
      name: "Sabrina M., ",
      place: "Birthday Party",
    },
    {
      text: "I thought Take My Photo Photobooth (Barrie) were phenomenal. Everyone enjoyed themselves and have been still talking about the photobooth itself and how they'd love to have a photobooth at their next event. Definitely pleased and willing to work with them next year as our annual photobooth providers.",
      name: "The Heart to Heart Foundation, ",
      place: " in support of Sick Kids Hospital",
    },
    {
      text: "Thank you so much for making my daughter's 16th birthday a memorable one! Everyone had such a good time with your props and fun photo booth! It was the talk of the party!",
      name: "Lorna D., ",
      place: "Sweet 16 Birthday Party",
    },
    {
      text: "Dear Brian & Shariq, Thank you so much for your services on our wedding day. Your creativity for my photo was exactly what we had envisioned The photo booth was a great hit. All of our guests were very impressed and they all expressed they never had so much fun. Your professionalism was exceptional as was your patience. Take Care, ",
      name: "Maggie and Matt, ",
      place: "Wedding",
    },
    {
      text: "Everyone had a great time! it was a lot of fun. Next time I will run it all night.",
      name: "Corrie R., ",
      place: "Strath Allen",
    },
    {
      text: "Brian and Shariq were an absolute pleasure to work with. They were prompt and accommodating from start to finish. They waited patiently for the duration of the party and remained enthusiastic the entire time. We would absolutely recommend Take My Photo because it made a good party great.",
      name: "Taylor D., ",
      place: "Canadian Tire",
    },
    {
      text: "I am so glad that we decided to go with a photobooth. The photobooth was the best idea and made our wedding reception a huge success. Brian and Shariq were amazing and fun to work with and their attitude and professionalism made all the difference. Definitely worth every penny and I would recommend this to anyone!!!!",
      name: "Justin P., ",
      place: "Wedding",
    },
    {
      text: "You guys did a fantastic job, you were extremely friendly and professional and made the guests feel comfortable!!!! You really made the evening and we will be booking you again for our next holiday party!!!!",
      name: "Angela D., ",
      place: "Iron Mountain",
    },
    {
      text: "Thanks very much - it is a pleasure to deal with you!",
      name: "Susan F., ",
      place: "Upper Canada College",
    },
    {
      text: "I had the best experience from start to finish. I would not hesitate referring Take My Photo - for personal events (weddings, birthdays, etc) to corporate functions. They were extremely quick at responding to inquiries, pleasant to deal with, created a beautiful design for the photos and provided quality, friendly service during the event. Our company holiday party was a blast thanks to Take My Photo!",
      name: "Sarah C., ",
      place: "Nightingale",
    },
    {
      text: "Everyone really enjoyed the photo booth! It added a nice element to our event. The ability to brand the print-out made the photos a wonderful keepsake. Thanks!",
      name: "Susanna D., ",
      place: "EF Toronto",
    },
    {
      text: "Great having you there.",
      name: "Nina and Manny",
      place: "Wedding",
    },
    {
      text: "Brian and Shariq were awesome!!! Great service and quality, will definitely be recommending!!!",
      name: "Pamela S., ",
      place: " Aditya Birla Minacs",
    },
    {
      text: "It was a pleasure working with the Take My Photo personnel. They were willing to go over and above to provide us with exceptional and professional service and well as great quality pictures. They were a BIG hit with our company for both our adult and children's Christmas parties. Looking forward to working with them again!!",
      name: "Maria I., ",
      place: "L-3 Communications",
    },
    {
      text: "This was the first time we had used a Photo booth at our Holiday Party and it was a huge success. Everyone had fun with the xmas props and loved the take away photo strip that was customized for our event. Shariq and Brian were great with our employees and drawing the shyer ones in to get their special photos taken. Great event addition, thanks so much.",
      name: "Janet W., ",
      place: "Akzonobel",
    },
    {
      text: "Awesome idea and well received by our staff. Great way to have instant take home memories of the event. Brian and staff were great to work with and so professional yet fun with our staff. So glad we decided on this addition to our holiday party. Well worth it!",
      name: "Nicole T., ",
      place: "Transat",
    },
    {
      text: "Take My Photo Barrie is such a fantastic highlight to any event. Brian and Shariq are personable, professional and get the job done with fun and flair. I am constantly recommending their services.",
      name: "Andrea R., ",
      place: "Tickled Pink PR",
    },
    {
      text: "TMP did a great job for us at our charity event and were very professional and fun with the kids all around the booth. Thank you so much from all the campers and staff at Camp Tamakwa!!!",
      name: "Craig P., ",
      place: "Camp Tamakwa",
    },
    {
      text: "Everyone loved it and had great fun taking the photos. ",
      name: "Betty C., ",
      place: "Birthday Party",
    },
    {
      text: "We have had a very positive response from all of my daughter's guests and will definitely recommend you to others.",
      name: "Melanie D., ",
      place: "Birthday Party",
    },
    {
      text: "The photo booth at Girlfriends Getaway in Huntsville was a big hit! The ladies loved it, and I was successful in raising funds for the Huntsville/Lake of Bays Chamber of Commerce. I've already booked for next year! ",
      name: "Trish K., ",
      place: "Huntsville Lake of Bays Chamber of Commerce",
    },
    {
      text: "I had the Take My Photo photo booth during my son's Bar Mitzvah party. Both kids and adults had so much fun taking pictures in the booth. The pictures were excellent quality. Many different props that were available made it even more exciting. The professionals that were operating the booth were great! Very accommodating, courteous, and friendly. They went above and beyond their duties dealing with the kids, helping them to glue some of the photos in the scrap book and writing their messages there. It was a real pleasure dealing with such professional, diligent, honest people! Thank you Brian and Shariq!",
      name: "Mariana S., ",
      place: "Bar Mitzvah",
    },
    {
      text: "Brian and Shariq where absolutely amazing and our guests loved them and the photobooth! The pictures are amazing, the quality fantastic and the service was second to none. We were thrilled to have them at Dream Halloween and will definitely use their services again and am more than happy to promote their wonderful business to others. Brian and Shariq, thank you for helping us make Dream Halloween an amazing night for our guests! People are still taking about you guys and how much fun the booth was!!",
      name: "Joanne W., ",
      place: "Alzheimer Society of York Region",
    },
    {
      text: "Truly outstanding service at all levels! Was super fun addition to our party...and great keepsakes for our guests...Thank you for the Fun!",
      name: "Odette N., ",
      place: "State Farm Insurance",
    },
    {
      text: "Our wedding was made extra special and fun by having Take My Photo at it. Our guests had an absolute blast and it's the talk of the town.",
      name: "John R., ",
      place: "Wedding",
    },
    {
      text: "Amazing and professional service. Guests were very impressed with the quality of the photos and had loads of fun with all the props.",
      name: "Lindsay M., ",
      place: "Wedding",
    },
    {
      text: "Take My Photo is the best favor you can give your guests to have. My family and friends had a blast and kept going back to take more photos with the fun and entertaining props that are provided. Guests get to keep these fun pictures which come in a choice of colour or in black and white. The photo had our wedding date and my husbands and I engagement photo as a border. The staff are friendly welcoming and helpful. They will reserve your date with a deposit and work with you to get a layout for the photos to your satisfaction. Take My Photo will provide you with a copy of all the photos taken on a disk for your own keepsake. My husband and I are extremely happy with our decision to have Take My Photo as part of our wedding memories that we will keep forever.",
      name: "Jacqueline and James, ",
      place: "Wedding",
    },
    {
      text: "So much fun! It really got people up, having fun and laughing after dinner. The picture quality is great and we had a good laugh looking at all of the pictures afterwards.",
      name: "Janice T., ",
      place: "Wedding",
    },
    {
      text: "Excellent Service could not have asked for more. Will use again for future events.",
      name: "Marsha S., ",
      place: "Ryerson University Tri-Mentoring Program",
    },
    {
      text: "Keep doing what you are doing. You guys are the only photobooth service I know of (especially after doing research into several other photobooth services prior to choosing this one) that offer a great service at a very reasonable cost and for that there is no reason why someone should not choose Take My Photo Photobooth. But to top affordability off (which is always a priority when budgeting for a wedding), I also appreciate your flexibility and responsiveness to my questions and requests via email in a very timely, friendly, and professional manner. You really put customer service as a priority and that is what matters the most to me. I really feel this service made our wedding and night even more special for us and all of our guests. Thank you Brian and Shariq for everything!!",
      name: "Zhyra and Bhavik, ",
      place: "Wedding",
    },
    {
      text: "Throughout planning our wedding reception, it was very easy to communicate with Take My Photo. Their prompt response to our inquiries throughout the planning process showcased their professionalism and commitment to delivering great customer service which they certainly achieved! The day of our reception, they exhibited great energy and interacted so well with our guests which is something I don't see from many other photo booth companies. The picture quality was amazing and our guests had such a great experience with them. We really appreciate their services - definitely worth their prices! I would highly recommend their services!",
      name: "Shenelle & Michael, ",
      place: "Wedding",
    },
    {
      text: `The photo booth was a huge hit at my best friend's wedding. A few friends chipped in to provide this as a gift and we were all very proud to make this available for the bride, groom and all the guests. Everyone had a great time with all the fun props. I love that we get instant prints and that the digital pictures will also be available. One guest said "Every event should have a photo booth!"`,
      name: "Susan G., ",
      place: "Wedding",
    },
  ];
  const reviewImgArray = [
    { img: arthritisImg, alt: "Arthritis Research Foundation Testimonial" },
    { img: georgeImg, alt: "George Brown College Students Testimonial" },
    { img: nottawasagaImg, alt: "Nottawasaga Futures Customer Service Award" },
  ];
  return (
    <HelmetProvider>
      <div className="wrapper">
        <Helmet>{script && <script>wpShowReviews(7858, "red")</script>}</Helmet>
        <h1 className="orangeBlackTitle reviewTitle">
          Read Our <span className="highlightOrange">Awesome</span> Reviews
        </h1>
        <div className="reviewsContainer">
          <div id="wp-widget-reviews">
            <div id="wp-widget-preview ">
              Read{" "}
              <a
                href="https://www.weddingwire.ca/photobooth/take-my-photo--e7858/reviews"
                target="_blank"
                rel="noreferrer"
                aria-label="Will direct you to Wedding Wire Take My Photo reviews"
              >
                View reviews:
              </a>{" "}
              in &nbsp;
              <a
                href="https://www.weddingwire.ca"
                target="_blank"
                rel="noreferrer"
                aria-label="Will direct you to Wedding Wire"
              >
                <img
                  src="https://cdn1.weddingwire.ca/assets/img/logos/gen_logoHeader.svg"
                  height="20"
                  alt="Click here to go to Wedding Wire reviews (opens on new tab)"
                />
              </a>
            </div>
          </div>

          {reviewsArray.map((testimonial, i) => {
            return (
              <div key={i}>
                <hr className="reviewHr" />
                <div className="testimonialContainer">
                  <img
                    src={quote}
                    alt="Quote"
                    aria-hidden="true"
                    className="quote"
                  />
                  <div className="testimonialText reviewText">
                    <p>{testimonial.text}</p>
                    <p className="nameReview">
                      <span>{testimonial.name}</span> {testimonial.place}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
          {reviewImgArray.map((img, i) => {
            return (
              <div key={i}>
                <hr className="reviewHr" />
                <div className="reviewImgContainer">
                  <img src={img.img} alt={img.alt} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </HelmetProvider>
  );
}
